<div class="spinner" *ngIf="!isInitialized">
  <mat-spinner matSuffix style="margin-right: 5px;" mode="indeterminate" [diameter]="50"></mat-spinner>
</div>

<div class="watermark-wrapper" *ngIf="phase == 'ppprd'">
  <div class="watermark">{{phase | uppercase}}</div>
</div>

<ng-container *ngIf="isInitialized">
  <router-outlet (activate)="onActivate()"></router-outlet>
</ng-container>

<ng-container *ngIf="displayCookiesBanner">
  <app-cookies></app-cookies>
</ng-container>



