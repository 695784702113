// tslint:disable:variable-name
export class ConsultationLotsListRep {
    cle_dce = '';
    lots!: LotItem[];
    nb_total = 0;
}

export interface LotItem {
    cle: string;
    libelle: string;
    libelle_ordre: string;
    agregat: string;
    taille: number;
    taille_f: string;
}
// tslint:enable:variable-name
