import { ConsultationRequestCode } from './../../shared/enums/api.enum';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import { environment } from 'src/environments/environment';

import { Observable, throwError } from 'rxjs';
import { QrAddResponse } from '../models/api/responses/qradd.response';
import {
  ConsultationResponse,
  CreateSemResponse,
  DeriverConsultationResponse,
} from '../models/api/responses/consultations.response';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import {
  ConsultationRequest,
  ConsultationPiecesListResquest,
  ConsultationGetPieceRequest,
  SendToJalRequest,
  PostArchiveDceRequest,
  DeriverConsultationRequest,
  ConsulterTitulaireRequest,
  GetConsultationJournal,
  GetConsultationJournnal,
  CreateSemRequest,
} from '../models/api/requests/consultation.request';
import { QuestionsAndAnwsersResquest } from '../models/api/requests/questionsAndAnwsers.request';
import { QrPdfResquest } from '../models/api/requests/questionsAndAnwsers.request';
import { QuestionsAndAnwsersResponse } from '../models/api/responses/questionsAndAnwsers.response';
import { QrAddRequest } from '../models/api/requests/qradd.request';
import { SessionStorage } from 'src/app/shared/enums/session-storage.enum';
import { UnPublishedConsultationsListRequest } from '../models/api/requests/unpublishedConsultationList.request';
import { ConsultationDisplayReponse } from '../models/api/responses/consultationDisplay.reponse';
import {
  CreateConsultationRequest,
  GetConsultationDetailsRequest,
} from '../models/api/requests/consultation.request';
import { AddPieceConstitutivesRequest } from '../models/api/requests/addPieceConstitutives.request';
import { PiecesResponse } from './../models/api/responses/pieces/pieces.response';
import { DeleteConsultationRequest } from '../models/api/requests/deleteConsultation.request';
import { VerifyRulesConsultationResponse } from '../models/api/responses/verifyRulesConsultation.response';
import { VerifyRulesConsultationRequest } from '../models/api/requests/verifyRulesConsultation.request';
import { RemovePieceOnUnpublishedConsultationRequest } from '../models/api/requests/removePIeceOnUnpublishedConsultation.request';
import { DefaultResponse } from '../models/api/responses/default.response';
import {
  GetStatePublishRequest,
  PublishConsultationRequest,
} from '../models/api/requests/publishConsultation.request';
import { ConsultationLotsListRequest } from '../models/api/requests/consultationLotsList.request';
import { ConsultationLotsListRep } from '../models/api/responses/consultationLotsList.reponse';
import { MethodRequest } from 'src/app/shared/enums/method-request.enum';
import { MessageResponse } from '../models/api/responses/message.response';

import { RequestForPa } from '../models/api/requests/requestForPa.request';
import { InformOeRequest } from '../models/api/requests/informOe.request';
import { PieceLabelEdit } from '../models/api/requests/piece.request';
import { PieceItem2 } from '../models/pieceItem2.model';
import RequestHelper from '../helpers/request.helper';
import { ConsultationPaItem } from '../models/consultation/ConsultationPaItem.model';
import { ConsultationDetailItem } from '../models/consultation/ConsultationDetailItem.model';
import { tap } from 'rxjs/operators';
import { ConsultationOeItem } from '../models/consultation/ConsultationOeItem.model';
import { GetExportRetraitCsvRequest } from '../models/api/requests/depotsRetraitList.request';
import { ConsultationSemItem } from '../models/consultation/ConsultationSem.model';
@Injectable({
  providedIn: 'root',
})
export class ConsultationsService {
  constructor(
    private readonly utilsService: UtilsService,
    private readonly apiService: ApiService,
    private http: HttpClient
  ) { }

  /**
   * Get detail of consultation retrieved by DCE key
   * @param params request params
   * @param code UPC = unpublished consultation PCT = published consultation
   * @returns observable with array of consultation
   */
  getConsultation(
    params: GetConsultationDetailsRequest
  ): Observable<ConsultationResponse> {
    return this.apiService.post(ConsultationResponse, params, 'fw_consultation_details').pipe(
      tap((res) =>
        res.consultations = new ConsultationDetailItem(res.consultations))
    )
  }

  /**
   * Get list of pieces for consultation selected by DCE key
   * @param params request params
   * @returns observable with pieces array
   */
  getConsultationPieces(
    params: ConsultationPiecesListResquest,
    code: ConsultationRequestCode = ConsultationRequestCode.PCT,
    side: 'oe' | 'pa'
  ): Observable<PiecesResponse> {
    const wsPath =
      code === ConsultationRequestCode.PCT
        ? side === 'oe' ? 'oe_dce_pieces_list' : 'dce_pieces_list'
        : 'unpublished_consultation_piece_list';
    return this.apiService.post(PiecesResponse, params, wsPath);
  }

  getPaConsultationPieces(
    params: ConsultationPiecesListResquest
  ): Observable<PiecesResponse> {
    return this.apiService.post(PiecesResponse, params, 'pa_dce_pieces_list');
  }

  /**
   *
   * @returns array of all consulation
   */
  consultationList(
    params: ConsultationRequest = {}
  ): Observable<ConsultationResponse> {
    // temp solution for WS call
    return this.http.get<ConsultationResponse>(
      environment.api + 'consultations_list',
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.GET)
          .headers,
        params: RequestHelper.buildParams(params),
      }
    );
  }

  consultationPaList(params: ConsultationRequest): Observable<ConsultationResponse> {
    return this.apiService.get(ConsultationResponse, params, 'consultations_list_pa').pipe(
      tap((res) => res.consultations = res.consultations.map((c: any) => new ConsultationPaItem(c)))
    );
  }

  /**
   *
   * @returns array of all consulation
   */
  consultationListOe(
    params: ConsultationRequest = {}
  ): Observable<ConsultationResponse> {
    return this.apiService.get(ConsultationResponse, RequestHelper.buildParams(params), 'consultations_list_oe').pipe(
      tap((res) => res.consultations = res.consultations.map((c: any) => new ConsultationOeItem(c))
      ));
  }

  consultationUnpublished(
    request: UnPublishedConsultationsListRequest
  ): Observable<ConsultationResponse> {
    return this.apiService.post(
      ConsultationResponse,
      request,
      'unpublished_consultations_list'
    ).pipe(
      tap((res) => res.consultations = res.consultations.map((c: any) => new ConsultationPaItem(c))
      ));;
  }

  DeleteUnpulishedConsultationPieces(
    req: RemovePieceOnUnpublishedConsultationRequest
  ): Observable<DefaultResponse> {
    return this.apiService.post(
      DefaultResponse,
      req,
      'consultation_piece_remove'
    );
  }

  createConsultation(
    createConsultationRequest: CreateConsultationRequest
  ): Observable<ConsultationResponse> {
    return this.apiService.post(
      ConsultationResponse,
      createConsultationRequest,
      'consultation_edit'
    ).pipe(
      tap((res) => {
        res.consultations = res.consultations.map((c: any) => new ConsultationDetailItem(c))
      })
    );
  }

  displayConsultationStructure(
    displayConsultationRequest: RequestForPa
  ): Observable<ConsultationDisplayReponse> {
    return this.apiService.postClient(
      displayConsultationRequest,
      'consultation_display'
    );
  }

  publishConsultation(
    req: PublishConsultationRequest
  ): Observable<ConsultationResponse> {
    return this.apiService.post(ConsultationResponse, req, 'consultation_publish').pipe(
      tap((res) => {
        res.consultations = res.consultations.map((c: any) => new ConsultationPaItem(c))
      })
    );
  }

  getPublishState(req: GetStatePublishRequest): Observable<DefaultResponse> {
    return this.apiService.post(
      DefaultResponse,
      req,
      'consultation_publish_step'
    );
  }

  addPieceConstitutives(
    addPieceConstitutivesRequest: AddPieceConstitutivesRequest
  ): Observable<any> {
    const formData = new FormData();
    formData.append('cle_dce', addPieceConstitutivesRequest.cle_dce);

    if (addPieceConstitutivesRequest.content)
      formData.append(
        'fileToUpload',
        addPieceConstitutivesRequest.content,
        addPieceConstitutivesRequest.content.name
      );

    formData.append('cle_etab', addPieceConstitutivesRequest.cle_etab);

    if (addPieceConstitutivesRequest.cle_lot)
      formData.append('cle_lot', addPieceConstitutivesRequest.cle_lot);

    if (addPieceConstitutivesRequest.cle_piece)
      formData.append('cle_piece', addPieceConstitutivesRequest.cle_piece);

    if (addPieceConstitutivesRequest.is_aapc)
      formData.append('is_aapc', addPieceConstitutivesRequest.is_aapc);

    if (addPieceConstitutivesRequest.libelle)
      formData.append('libelle', addPieceConstitutivesRequest.libelle);

    if (addPieceConstitutivesRequest.ordre)
      formData.append('ordre', addPieceConstitutivesRequest.ordre.toString());

    return this.http.post(environment.api + 'dce_piece_edit', formData, {
      reportProgress: true,
      headers: this.apiService.getHttpOptions(null, MethodRequest.GET).headers,
      observe: 'events',
    });
  }

  consultationLotsList(
    request: ConsultationLotsListRequest
  ): Observable<ConsultationLotsListRep> {
    return this.apiService.post(
      ConsultationLotsListRep,
      request,
      'consultation_lots_list'
    );
  }

  getPiece(
    consGetPieceRequest: ConsultationGetPieceRequest
  ): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.api + 'consultation_piece_get',
      consGetPieceRequest,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.GET)
          .headers,
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  getPieceLink(req: ConsultationGetPieceRequest, side: 'oe' | 'pa' = 'pa'): string {
    const paramsStr = Object.entries(req).reduce((params, [key, value], i) => {
      if (value) return `${params}${i > 0 ? '&' : ''}${key}=${value}`;
      return params;
    }, '');
    return `${environment.api}${ side === 'oe' ? 'get_oe_dce_pieces' : 'get_dce_pieces'}?access_token=${this.utilsService.token
      }${paramsStr ? '&' : ''}${paramsStr}`;
  }

  getJournalHistorique(
    req: GetConsultationJournal
  ): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.api}dce_journal_historique`, req, {
      headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
      responseType: 'blob',
      observe: 'response',
    });
  }
  getJournalExanche(
    req: GetConsultationJournal
  ): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.api}dce_journal_echanges_oe`, req, {
      headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
      responseType: 'blob',
      observe: 'response',
    });
  }
  getJournalRetraits(
    req: GetConsultationJournal
  ): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.api}dce_journal_retrait`, req, {
      headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
      responseType: 'blob',
      observe: 'response',
    });
  }

  getJournalRetraitsCsv(
    req: GetExportRetraitCsvRequest
  ): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.api}export_registre_retrait_csv`, req, {
      headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
      responseType: 'blob',
      observe: 'response',
    });
  }

  getJournalDepots(req: GetConsultationJournal): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.api}dce_journal_depot`, req, {
      headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
      responseType: 'blob',
      observe: 'response',
    });
  }

  getJournalDepotCompany(req: GetConsultationJournnal): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.api}dce_journal_depot`, req, {
      headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
      responseType: 'blob',
      observe: 'response',
    });
  }
  getJournalDepotBatches(req: GetConsultationJournnal): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.api}dce_journal_depot`, req, {
      headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
      responseType: 'blob',
      observe: 'response',
    });
  }
  getJournalNotifications(req: GetConsultationJournnal): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.api}dce_journal_depot`, req, {
      headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
      responseType: 'blob',
      observe: 'response',
    });
  }
  getJournalDc(req: GetConsultationJournal): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.api}dce_journal_demandes_comp`, req, {
      headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
      responseType: 'blob',
      observe: 'response',
    });
  }
  getSelectedJournals(req: GetConsultationJournnal): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.api}dce_merged`, req, {
      headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
      responseType: 'blob',
      observe: 'response',
    });
  }

  getQuestions(
    req: QuestionsAndAnwsersResquest
  ): Observable<QuestionsAndAnwsersResponse> {
    return this.apiService.post(QuestionsAndAnwsersResponse, req, 'qr_list');
  }

  addQuestion(req: QrAddRequest): Observable<QrAddResponse> {
    return this.apiService.post(QrAddResponse, req, 'qr_add');
  }


  getQrPdf(request: QrPdfResquest): Observable<HttpResponse<any>> {
    return this.http.post(
      `${environment.api}export_pdf_couriel`,
      request,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.GET).headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  isDemo(): number {
    return this.utilsService.getSessionStorage(
      SessionStorage.USER_SESSION,
      true
    )
      ? this.utilsService.getSessionStorage(SessionStorage.USER_SESSION, true)
        .profile.user.demo
      : false;
  }

  deleteUnpublishedConsultation(
    req: DeleteConsultationRequest
  ): Observable<DefaultResponse> {
    return this.apiService.post(
      DefaultResponse,
      req,
      'unpublished_consultation_remove'
    );
  }

  deleteConsultation(
    req: DeleteConsultationRequest
  ): Observable<DefaultResponse> {
    return this.apiService.post(DefaultResponse, req, 'consultation_remove');
  }

  informOePublish(req: InformOeRequest): Observable<DefaultResponse> {
    return this.apiService.post(DefaultResponse, req, 'inform_oe');
  }

  /**
   * Verify on consultation edition
   * @param req req data
   */
  getVerifyRules(
    req: VerifyRulesConsultationRequest
  ): Observable<VerifyRulesConsultationResponse> {
    return this.apiService.postClient(req, 'consultation_verify_rules');
  }

  postPieceLabel(req: PieceLabelEdit): Observable<PieceItem2> {
    return this.apiService.post(
      PieceItem2,
      req,
      'consultation_piece_libelle_edit'
    );
  }

  sendAapcToJal(req: SendToJalRequest): Observable<DefaultResponse> {
    return this.apiService.post(DefaultResponse, req, 'consultation_send_jal');
  }

  postArchiveDce(req: PostArchiveDceRequest): Observable<DefaultResponse> {
    return this.apiService.post(DefaultResponse, req, 'archive_switch_dce');
  }

  postDeriver(
    req: DeriverConsultationRequest
  ): Observable<DeriverConsultationResponse> {
    return this.apiService.post(
      DeriverConsultationResponse,
      req,
      'consultation_derive'
    );
  }

  postConsulterTitulaire(
    req: ConsulterTitulaireRequest
  ): Observable<DeriverConsultationResponse> {
    return this.apiService.post(
      DeriverConsultationResponse,
      req,
      'dce_consultation_titulaire'
    );
  }

  createSem(req: CreateSemRequest): Observable<CreateSemResponse> {
    return this.apiService.post(CreateSemResponse, req, 'add_sem_dce').pipe(tap(res => res = new ConsultationSemItem(res)));
  }

  // TODO remove this
  errorMgmt(error: HttpErrorResponse): any {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  verifyCodeRestreint(
    cleDce: string,
    codeRestreinte: string,
    cleEnt: string
  ): Observable<MessageResponse> {
    return this.apiService.post(
      MessageResponse,
      { cle_dce: cleDce, code_restreinte: codeRestreinte, cle_ent: cleEnt },
      'dce_code_restreint_verify'
    );
  }
}
