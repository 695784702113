import { Component } from '@angular/core';
import { Icons } from '@atline-shared/enums/icons.enum';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent {

  iconInfo = Icons.Info;

  constructor() {}

}
