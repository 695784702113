import { DateClotureDefault, DatePublicationDefault, DefaultFieldConsultation } from "./DefaultFieldConsultation.model";
import { AccessConsultation } from "./BaseConsultation.model";

export class ConsultationSemItem extends DefaultFieldConsultation {

  private code_acces!: string;


  constructor(consultation: any) {    
    super(consultation);
    this.code_acces = consultation.code_acces;
  }

  get access(): AccessConsultation {
    return {
      isRestreinte: true,
      code: this.code_acces
    }
  }

  get dateCloture(): DateClotureDefault {
    return this.dateClotureDefault
  }

  get datePublication(): DatePublicationDefault {
    return {
      ...this.datePublicationDefault
    }
  }
}
