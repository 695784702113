import { AffaireCreateRequest } from '../models/api/requests/affaireCreate.request';
import { Observable } from 'rxjs';
import { AffaireListResponse } from '../models/api/responses/affaireList.response';
import { AffaireListRequest } from '../models/api/requests/affaireList.request';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { DeleteAffaireRequest } from '../models/api/requests/deleteAffaire.request';
import { ClotureOuvertureAffaireRequest } from '../models/api/requests/clotureOuvertureAffaire.request';
import { MessageResponse } from '../models/api/responses/message.response';
import { AffaireItem } from '../models/affaireItem.model';

@Injectable({
  providedIn: 'root'
})
export class AffaireService {

  constructor(private readonly apiService: ApiService) { }

  /**
   *
   * @param affaireListRequest request body for affaires_list
   * @returns list des affaires
   */
  getAffaire(affaireListRequest: AffaireListRequest): Observable<AffaireListResponse>{
    return this.apiService.post(AffaireListResponse, affaireListRequest, 'affaire_list');
  }

  /**
   *
   * @param createAffaireRequest request body for create an affaire
   * @returns données de l'affaires créée en base
   */
  createAffaire(createAffaireRequest: AffaireCreateRequest): Observable<AffaireItem>{
    return this.apiService.post(AffaireItem, createAffaireRequest, 'affaire_edit');
  }

  clotureOuverture(clotureOuvertureAffaireRequest: ClotureOuvertureAffaireRequest): Observable<AffaireItem>{
    return this.apiService.post(AffaireItem, clotureOuvertureAffaireRequest, 'affaire_state_edit');
  }

  deleteAffaire(deleteAffaireRequest: DeleteAffaireRequest): Observable<MessageResponse>{
    return this.apiService.post(MessageResponse, deleteAffaireRequest, 'affaire_delete');
  }


}
