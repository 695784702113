<div class="container" fxLayout="row nowrap" (click)="onChange()" [ngClass]="{'disabled': !isActive}">
    <div class="container-checkbox" [ngClass]="{'disabled': !isActive}">
        <div class="checkbox" [class.checked]="checked" [ngClass]="{'disabled': !isActive}">
            <input #input type="checkbox" [name]="name" [id]="code" class="checkbox" (change)="onChange();"
                [(ngModel)]="checked" [alt]="title" [disabled]="!isActive">
        </div>
    </div>
    <div class="container-description" [ngClass]="{'disabled': !isActive}">
        <img src='{{"/assets/icons/png/" + img + ".png"}}' [alt]="title" *ngIf="img">
        <ng-content [select]="'label[for=' + name + ']'" *ngIf="!img" style="white-space: normal;"></ng-content>
    </div>
</div>
