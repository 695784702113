import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class MsRightsService {

  constructor(
    private readonly utilsService: UtilsService
  ) { }

  /**
   *  D_SUP_DCE
   */
  get msRightsSuppDce(): boolean {
    return !!this.utilsService.msRights?.D_SUP_DCE;
  }

  /**
   *  D_REDAC_DCE
   */
  get msRightsConsultationCreation(): boolean {
    return !!this.utilsService.msRights?.D_REDAC_DCE;
  }

  /**
   *  D_SUPERVISION_DCE
   */
  get msRightsAccessConsultation(): boolean {
    return !!this.utilsService.msRights?.D_SUPERVISION_DCE;
  }

  /**
   *  D_ACCES_RD
   */
  get msRightsRegistreDepots(): boolean {
    return !!this.utilsService.msRights?.D_ACCES_RD;
  }

  /**
   *  D_ACCES_RR
   */
  get msRightsRegistreRetraits(): boolean {
    return !!this.utilsService.msRights?.D_ACCES_RR;
  }

  /**
   *  D_ACCES_AS
   */
  get msRightsAttestationSecurisee(): boolean {
    return !!this.utilsService.msRights?.D_ACCES_AS;
  }

  /**
   *  D_ANNONCES
   */
  get msRightsAnnonces(): boolean {
    return !!this.utilsService.msRights?.D_ANNONCES;
  }

  /**
   *  D_ACCES_CAO
   */
  get msRightsOpenResponses(): boolean {
    return !!this.utilsService.msRights?.D_ACCES_CAO;
  }

  /**
   *  D_ADMINISTRATEUR
   */
  get msRightsAdmin(): boolean {
    return !!this.utilsService.msRights?.D_ADMINISTRATEUR;
  }

  /**
   *  D_VALIDATION_DCE
   */
  get msRightsValidationLevel1(): boolean {
    return !!this.utilsService.msRights?.D_VALIDATION_DCE;
  }

  /**
   *  D_VALIDATION_DCE_2
   */
  get msRightsValidationLevel2(): boolean {
    return !!this.utilsService.msRights?.D_VALIDATION_DCE_2;
  }

  /**
   *  D_MODIF_NIVEAU_1
   */
  get msRightsModificationLevel1(): boolean {
    return !!this.utilsService.msRights?.D_MODIF_NIVEAU_1;
  }

  /**
   *  D_MODIF_NIVEAU_2
   */
  get msRightsModificationLevel2(): boolean {
    return !!this.utilsService.msRights?.D_MODIF_NIVEAU_2;
  }

  /**
   *  D_WEB_SERV
   */
  get msRightsWS(): boolean {
    return !!this.utilsService.msRights?.D_WEB_SERV;
  }

  /**
   *  D_MAIL_ENT
   */
  get msRightsSendMailToOe(): boolean {
    return !!this.utilsService.msRights?.D_MAIL_ENT;
  }

  /**
   *  D_MAIL_OE
   */
  get msRightsInformerOe(): boolean {
    return !!this.utilsService.msRights?.D_MAIL_OE;
  }

  /**
   *  D_GESTION_MODELE
   */
  get msRightsGererModelConsultation(): boolean {
    return !!this.utilsService.msRights?.D_GESTION_MODELE;
  }

  /**
   *  D_ENVOI_NOTIFICATION
   */
  get msRightsEnvoiNotifications(): boolean {
    return !!this.utilsService.msRights?.D_ENVOI_NOTIFICATION;
  }

  /**
   *  D_ENVOI_DEMANDE_COMP
   */
  get msRightsEnvoiDemandeComp(): boolean {
    return !!this.utilsService.msRights?.D_ENVOI_DEMANDE_COMP;
  }

  /**
   *  D_OUVRIR_DEMANDE_COMP
   */
  get msRightsOpenDemandeComp(): boolean {
    return !!this.utilsService.msRights?.D_OUVRIR_DEMANDE_COMP;
  }

  /**
   *  D_ENVOI_LRAR
   */
  get msRightsSendLrar(): boolean {
    return !!this.utilsService.msRights?.D_ENVOI_LRAR;
  }

  /**
   *  D_BOAMP_CONFIG
   */
  get msRightsConfigBoamp(): boolean {
    return !!this.utilsService.msRights?.D_BOAMP_CONFIG;
  }

  /**
   *  D_EXECUTION
   */
  get msRightsExec(): boolean {
    return !!this.utilsService.msRights?.D_EXECUTION;
  }

  /**
   *  D_COFFRE_ANNUAIRE
   */
  get msRightsCoffreAnnuaire(): boolean {
    return !!this.utilsService.msRights?.D_COFFRE_ANNUAIRE;
  }

  /**
   *  D_ACCES_AS
   */
  get msRightsTransfertToATST(): boolean {
    return !!this.utilsService.msRights?.D_ACCES_AS;
  }

  /**
   *  D_ACCES_ATST
   */
  get msRightsAtstVigilance(): boolean {
    return !!this.utilsService.msRights?.D_ACCES_ATST;
  }

  getCanUseSem(clePa: string) {
    const pa = this.utilsService.paListFromToken.find(p => p.cle_pa === clePa);
    if (pa)
      return pa.sem;
    return false;
  }

}
