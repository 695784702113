import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ManagerPaEditionService, TYPE_FORM_GROUP, TypeFormGroup } from '../manager-pa-edition.service';
import { EditAnnonceRequest } from '@atline/core/models/api/requests/annonce/editAnnonce.request';
import { BehaviorSubject, Observable, Subject, Subscription, forkJoin, of } from 'rxjs';
import { VerifyRulesResponse } from '@atline/core/models/api/responses/annonce/verifyRules.response';
import { AnnoncesService } from '@atline/core/services/annonces.service';
import { UtilsService } from '@atline/core/services/utils.service';
import { EditAnnonceResponse } from '@atline/core/models/api/responses/annonce/editAnnonce.response';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { FormDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { AccountsResponse } from '@atline/core/models/api/responses/annonce/accounts.response';
import { DepartmentService } from '@atline/core/services/department.service';
import { LotAnnonce, LotsListAnnonceResponse } from '@atline/core/models/api/responses/annonce/lotsList.response';
import { AnnonceEnum } from '@atline-shared/enums/annonce.enum';
import * as _ from 'lodash';
import { AccountItem } from '@atline/core/models/accounts.model';
import { MotsDescripteurService } from '@atline/core/services/mots-descripteur.service';
import { JalItem } from '@atline/core/models/api/responses/supportsList.response';
import { AnnonceDetails } from '@atline/core/models/api/responses/annonce/annonceDetails.response';
import { CountryService } from '@atline/core/services/country.service';
import { NutsService } from '@atline/core/services/nuts.service';
import { NutsItem } from '@atline/core/models/api/responses/nuts.response';
import { CountryItem } from '@atline/core/models/countryItem.model';
import { Departement } from '@atline/core/models/api/responses/department.response';
import { CpvItem } from '@atline/core/models/cvpItem.model';
import FORMS_DISPLAY from './models/forms-display.model';
import { OrganisationItem } from '@atline/core/models/api/organisation.response';
import { OrganiastionListAnnonceResponse } from '@atline/core/models/api/responses/annonce/organisationList.response';
import { AttributionAnnonce, AttributionsListAnnonceResponse } from '@atline/core/models/api/responses/annonce/attributionsList.response';
import { SoumissionnaireAnnonce, SoumissionnairesListAnnonceResponse } from '@atline/core/models/api/responses/annonce/soumissionnairesList.response';
import { OffresListAnnonceResponse } from '@atline/core/models/api/responses/annonce/offresList.response';
import { MarcheAnnonce, MarchesListAnnonceResponse } from '@atline/core/models/api/responses/annonce/marchesList.response';
import { EditAnnonceOrgansationResquest } from '@atline/core/models/api/requests/annonce/organisation.request';
import { OffreAnnonceItem } from '@atline/core/models/OffreAnnonceItem.model';
import { CurrencyItem } from '@atline/core/models/api/responses/currenciesList.response';
import { DialogsService } from '@atline/core/services/dialogs.service';
import { PartItem } from '@atline/core/models/PartItem.model';

@Injectable({
  providedIn: 'root'
})
export class ManagerPaEditionAnnonceService extends ManagerPaEditionService<EditAnnonceRequest> implements OnDestroy {

  private readonly annonceBS = new BehaviorSubject<EditAnnonceResponse | undefined>(undefined);
  readonly annonceChange = this.annonceBS.pipe();

  private readonly verifyRulesBS = new Subject<VerifyRulesResponse>();
  readonly verifyRulesChange = this.verifyRulesBS.pipe();

  private readonly loadingBS = new Subject<boolean>();
  readonly loadingChange = this.loadingBS.pipe();

  private readonly loadingLotBS = new Subject<boolean>();
  readonly loadingLotChange = this.loadingLotBS.pipe();

  private readonly lotsBS = new BehaviorSubject<LotAnnonce[]>([]);
  readonly lotsChange = this.lotsBS.pipe();

  private readonly organisationsBS = new BehaviorSubject<OrganisationItem[]>([]);
  readonly organisationsChange = this.organisationsBS.pipe();

  private readonly attributionsBS = new BehaviorSubject<AttributionAnnonce[]>([]);
  readonly attributionsChange = this.attributionsBS.pipe();

  private readonly soumissionnairesBS = new BehaviorSubject<SoumissionnaireAnnonce[]>([]);
  readonly soumissionnairesChange = this.soumissionnairesBS.pipe();

  private readonly partiesBS = new BehaviorSubject<PartItem[]>([]);
  readonly partieChange = this.partiesBS.pipe();

  private readonly offresBS = new BehaviorSubject<OffreAnnonceItem[]>([]);
  readonly offresChange = this.offresBS.pipe();

  private readonly marchesBS = new BehaviorSubject<MarcheAnnonce[]>([]);
  readonly marchesChange = this.marchesBS.pipe();

  readonly cleEtab = this.utils.cleEtab || '';
  public clePa!: string;

  private readonly datas = new Map<string, any>();

  readonly comptes: AccountItem[] = [];
  readonly supports: JalItem[] = [];
  // TODO call web service "seal/facturations_list"
  readonly facturations: { fact_classe_profit: string, fact_denomination: string }[] = [
    { fact_denomination: 'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.OPTIONS.DEBITEUR-PRIVE-PERS-PHYSIQUE', fact_classe_profit: 'debiteurPrivePersPhysique' },
    { fact_denomination: 'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.OPTIONS.DEBITEUR-PRIVE-PERS-MORALE', fact_classe_profit: 'debiteurPrivePersMorale' },
    { fact_denomination: 'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.OPTIONS.DEBITEUR-ETRANGER', fact_classe_profit: 'debiteurEtranger' },
    { fact_denomination: 'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.OPTIONS.ETAT', fact_classe_profit: 'etat' },
    { fact_denomination: 'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.OPTIONS.REGION', fact_classe_profit: 'region' },
    { fact_denomination: 'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.OPTIONS.DEPARTEMENT', fact_classe_profit: 'departement' },
    { fact_denomination: 'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.OPTIONS.GROUPEMENT-COLLECTIVITES', fact_classe_profit: 'groupementCollectivites' },
    { fact_denomination: 'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.OPTIONS.COMMUNE', fact_classe_profit: 'commune' },
    { fact_denomination: 'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.OPTIONS.AUTRES-ORGANISMES-PUBLICS', fact_classe_profit: 'autresOrganismesPublics' },
    { fact_denomination: 'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.OPTIONS.ETABLISSEMENTS-PUBLICS-SANTE', fact_classe_profit: 'etablissementsPublicsSante' },
  ];

  readonly technicals = [
    AnnonceEnum.ACCORD_CADRE,
    AnnonceEnum.QUALIFICATION_SYSTHEM,
    AnnonceEnum.SAD,
    AnnonceEnum.CONCOURS,
    AnnonceEnum.ELECTRONIC_CATALOG,
    AnnonceEnum.ELECTRONIC_AUCTIONS,
    AnnonceEnum.OTHERS
  ] as const;

  private cleAnnonce?: string;
  private cleAnnonceur?: string;
  private managerInit = false;
  private subs = new Subscription();
  displayAllFields = true;
  private loadingData = { organisation: false, rectifications: false, parties: false }
  private error = { organisation: { update: false, get: false, delete: false }, rectifications: { update: false, get: false, delete: false }, parties: { update: false, get: false, delete: false } }

  private displayBS = new BehaviorSubject<FormDisplay | undefined>(undefined);
  readonly displayChange = this.displayBS.pipe();

  constructor(
    @Inject(TYPE_FORM_GROUP) controls: TypeFormGroup<EditAnnonceRequest>,
    private readonly annoncesServices: AnnoncesService,
    private readonly departementService: DepartmentService,
    private readonly utils: UtilsService,
    private readonly motsDescripteurService: MotsDescripteurService,
    private readonly countryService: CountryService,
    private readonly nutsService: NutsService,
    private readonly dialogService: DialogsService,
    private readonly utilsService: UtilsService,
    route: ActivatedRoute
  ) {
    super(controls);

    const paramsChange = route.paramMap.subscribe(params => {
      this.subs.unsubscribe();
      this.subs = new Subscription();
      this.managerInit = false;
      this.cleAnnonce = params.get('cleAnnonce') || undefined;
      this.initService();
      this.setValues({ cle_pa: this.annonce?.cle_pa ?? '' });
    });
    this.subs.add(paramsChange);
  }

  // INITIALIZERS
  private initService(): void {
    if (!this.cleAnnonce || !this.cleEtab) return;
    const initAnnonce = (res?: EditAnnonceResponse): void => {
      this.annonceBS.next(res);
      this.managerInit = true;
      if (res) this.initChanges();
    };

    this.annoncesServices.getAnnonceDetails({ cle_etab: this.cleEtab, cle_annonce: this.cleAnnonce })
      .pipe(
        tap(res => {
          this.clePa = res.cle_pa;
          const values = this.annonceToRequest(res);
          this.setValues(values);
          this.previous = values;
        }),
        tap(({ cle_annonceur }) => this.cleAnnonceur = cle_annonceur),
        mergeMap(res => ['EFORM_MODIFICATION', 'EFORM_MODIFICATION_ATTRIBUTION'].includes(res.type_formulaire) ? this.callAllWSForModif(res) : this.callAllWS(res)),
        tap(({ type_formulaire, refSeal }) => {
          this.setFormDisplay(refSeal?.formType ?? type_formulaire)
        })
      )
      .subscribe({
        next: res => initAnnonce(res),
        error: () => initAnnonce(),
      });
  }

  private initChanges(): void {
    const saved = this.onSave.pipe(
      filter(({ previous, current }) => !_.isEqual(previous, current)),
      tap(() => this.loadingBS.next(true)),
      mergeMap(() => this.editCall()),
      // mergeMap(() => this.verifyRulesCall()),
      tap(() => this.loadingBS.next(false)),
    ).subscribe();
    this.subs.add(saved);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // GETTER / SETTER
  get init(): boolean {
    return this.managerInit;
  }

  get annonce(): EditAnnonceResponse | undefined {
    return this.annonceBS.value;
  }

  get display(): FormDisplay | undefined {
    return this.displayBS.value;
  }

  get cle_annonce(): string | undefined {
    return this.cleAnnonce;
  }

  get cle_annonceur(): string | undefined {
    return this.cleAnnonceur;
  }

  get lots(): LotAnnonce[] {
    return this.lotsBS.value;
  }

  get isEforms(): boolean {
    return this.annonce?.isEform ?? false
  }

  get loading() {
    return this.loadingData;
  }

  get errors() {
    return this.error;
  }

  get organisations(): OrganisationItem[] {
    return this.organisationsBS.value;
  }

  get attributions(): AttributionAnnonce[] {
    return this.attributionsBS.value;
  }

  get soumissionnaires(): SoumissionnaireAnnonce[] {
    return this.soumissionnairesBS.value;
  }

  get parties(): PartItem[] {
    return this.partiesBS.value;
  }

  get offres(): OffreAnnonceItem[] {
    return this.offresBS.value;
  }

  get marches(): MarcheAnnonce[] {
    return this.marchesBS.value;
  }

  // METHODS
  annonceToRequest(annonce: EditAnnonceResponse): EditAnnonceRequest {
    const o: any = Object.assign({ cle_etab: this.cleEtab }, annonce);
    for (const key in o) {
      if (o.hasOwnProperty(key)) {
        const value = o[key];
        if (typeof value === 'boolean') o[key] = +value + '';
        else if (typeof value === 'number') o[key] = value + '';
      }
    }
    return o as EditAnnonceRequest;
  }

  save(): void {
    super.save();
  }

  // getParties(): Observable<PartResponse> {
  //   this.loadingData.parties = true;
  //   this.error.parties.get = false;

  //   return this.annoncesServices.getParties({
  //     cle_annonce: this.cleAnnonce || '',
  //     cle_etab: this.cleEtab,
  //     cle_pa: this.clePa
  //   }).pipe(
  //     catchError(() => { this.loadingData.parties = false; return of(undefined) }),
  //     tap(values => {
  //       this.partiesBS.next(values.parties);
  //       this.loadingData.parties = false;
  //     })
  //   )
  // }

  // addNewPartie(nom: string) {
  //   return this.annoncesServices.editPartie({ cle_etab: this.cleEtab, cle_pa: this.clePa, cle_annonce: this.cle_annonce || '', title: nom }).pipe(
  //     catchError(() => { this.loadingData.organisation = false; return of(null) }),
  //     tap((res) => {
  //       this.partiesBS.next([...this.parties, res]);
  //     })
  //   )
  // }

  // savePartie(partieData: EditPartieRequest) {
  //   return this.annoncesServices.editPartie(partieData).pipe(
  //     catchError(() => { this.loadingData.parties = false; return of(null) }),
  //     tap((res) => {
  //       const index = _.findIndex(this.parties, ['cle', partieData.clePartie]);
  //       const tempPartie = this.parties;
  //       tempPartie[index] = res;
  //       this.organisationsBS.next(tempPartie);
  //     })
  //   )
  // }

  // removePartie(partie: PartItem): Observable<any> {
  //   if (this.cle_annonce && partie.clePartie) {
  //     return this.annoncesServices.deletePartie({ cle_part: partie.clePartie, cle_annonce: this.cle_annonce, cle_etab: this.cleEtab, cle_pa: this.annonce?.cle_pa ?? '' }).pipe(
  //       catchError(() => { this.loadingData.parties = false; return of(null) }),
  //       tap(() => {
  //         const index = _.findIndex(this.parties, ['cle', partieData.clePartie]);
  //         const tempPartie = this.parties;
  //         tempPartie[index] = res;
  //         this.organisationsBS.next(tempPartie);
  //       })
  //     )
  //   }
  //   return of(null);
  // }

  getOrganisations(): Observable<OrganiastionListAnnonceResponse> {
    this.loadingData.organisation = true;
    this.error.organisation.get = false;

    return this.annoncesServices.getOrganisations({
      cle_annonce: this.cleAnnonce || '',
      cle_etab: this.cleEtab,
      cle_pa: this.clePa
    }).pipe(
      catchError(() => { this.loadingData.organisation = false; return of(undefined) }),
      tap(values => {
        const pa = this.utilsService.getPaList().find(pa => pa.cle_pa === this.clePa);
        if (pa) {
          this.organisationsBS.next([{ numero: 'ORG-0001', nom: pa.denomination_pa }, ...values.organisations]);
        } else {
          this.organisationsBS.next(values.organisations);
        }
        this.loadingData.organisation = false;
      })
    )
  }

  addNewOrganisation(nom: string) {
    return this.annoncesServices.createUpdateOrganisation({ cle_etab: this.cleEtab, cle_pa: this.clePa, cle_annonce: this.cle_annonce, nom }).pipe(
      catchError(() => { this.loadingData.organisation = false; return of(null) }),
      tap((res) => {
        this.organisationsBS.next([...this.organisations, res.organisations[0]]);
      })
    )
  }

  saveOrganisation(organisationData: EditAnnonceOrgansationResquest) {
    return this.annoncesServices.createUpdateOrganisation(organisationData).pipe(
      catchError(() => { this.loadingData.organisation = false; return of(null) }),
      tap((res) => {
        const index = _.findIndex(this.organisations, ['cle', organisationData.cle]);
        const tempOrg = this.organisations;
        tempOrg[index] = res.organisations[0];
        this.organisationsBS.next(tempOrg);
      })
    )
  }

  removeOrganisation(organisation: OrganisationItem): Observable<any> {
    if (this.cle_annonce && organisation.cle) {
      return this.annoncesServices.deleteOrganisation({ cle_organisation: organisation.cle, cle_annonce: this.cle_annonce, cle_etab: this.cleEtab, cle_pa: this.annonce?.cle_pa ?? '' }).pipe(
        catchError(() => { this.loadingData.organisation = false; return of(null) }),
        tap(() => {
          const index = _.findIndex(this.organisations, ['cle_organisation', organisation.cle]);
          const tempOrg = this.organisations
          tempOrg.splice(index, 1);
          this.organisationsBS.next(tempOrg);
        })
      )
    }
    return of(null);
  }

  // PRIVATE METHODS
  private editCall(): Observable<EditAnnonceResponse> {
    return this.annoncesServices.editAnnonce({
      ...this.values,
      cle_pa: this.clePa,
      cle_etab: this.cleEtab
    }).pipe(
      catchError(() => of(undefined)),
      filter((value): value is EditAnnonceResponse => !!value),
      tap(values => {
        this.annonceBS.next(values);
        this.setValues(this.annonceToRequest(values));
      })
    );
  }

  private verifyRulesCall(): Observable<VerifyRulesResponse> {
    return this.annoncesServices.verifyRules({
      cle_annonce: this.cleAnnonce || '',
      cle_etab: this.cleEtab,
      cle_pa: this.clePa
    }).pipe(tap(verify => this.verifyRulesBS.next(verify)));
  }

  refreshVerifyRulesCall(): void {
    this.verifyRulesCall().subscribe();
  }

  private setFormDisplay(type: string): void {
    const f: any = FORMS_DISPLAY[type.toLowerCase() as keyof typeof FORMS_DISPLAY] || undefined;
    this.displayBS.next(f);
  }

  private getAccountsCall(): Observable<AccountsResponse> {
    return this.annoncesServices.getAccounts({
      cle_pa: this.clePa,
      cle_etab: this.cleEtab,
      cle_annonceur: this.cleAnnonceur || ''
    }).pipe(tap(res => {
      this.comptes.length = 0;
      this.comptes.push(...res);
    }));
  }

  private getLots(): Observable<LotsListAnnonceResponse> {
    this.loadingLotBS.next(true);
    return this.annoncesServices.lots_list({
      cle_pa: this.clePa,
      cle_etab: this.cleEtab,
      cle_annonce: this.cleAnnonce || '',
    }).pipe(tap(({ lots }) => {
      _.sortBy(lots, ['numero_lot']);
      this.lotsBS.next(lots);
      this.loadingLotBS.next(false);
    }));
  }

  addLot() {
    this.dialogService.openInputFieldDialog('APP.DIALOG.INPUT_FIELD.LOT.LABEL', 'APP.DIALOG.INPUT_FIELD.LOT.TITLE', (res) => {

      if (this.lots.map(l => l.numero_lot).findIndex((l) => l == res) != -1) {
        this.dialogService.createInfoDialog('Numéro lot déjà existant', 'Le numéro de lot que vous avez saisie existe déjà. Veuillez le modifier avant de sauvegarder votre lot', 'Fermer');
        return;
      }

      if (this.annonce && !_.isNil(res))
        this.annoncesServices.editLot({ cle_annonce: this.annonce.cle_annonce, cle_etab: this.cleEtab, cle_pa: this.clePa, numero_lot: res }).subscribe({
          next: (res) => {
            this.lotsBS.next([...this.lots, res]);
          },
          error: () => {
            this.dialogService.createInfoDialog('Organisation', 'Une erreur est survenu dans la création du lot. Veuillez réessayer ultérieurement', 'OK');
          }
        })
    })
  }

  refreshLots() {
    this.getLots().subscribe({ next: (res) => this.lotsBS.next(res.lots) });
  }

  private getSoumissionnaires(): Observable<SoumissionnairesListAnnonceResponse> {
    return this.annoncesServices.soumissionnaires_list({
      cle_pa: this.clePa,
      cle_etab: this.cleEtab,
      cle_annonce: this.cleAnnonce || '',
    }).pipe(
      tap(({ soumissionnaires }) => {
        const pa = this.utilsService.getPaList().find(pa => pa.cle_pa === this.clePa);
        if (pa) {
          this.soumissionnairesBS.next([{ numero: 'TPA-0001', nomPartie: pa.denomination_pa }, ...soumissionnaires]);
        } else {
          this.soumissionnairesBS.next(soumissionnaires);
        }
      }));
  }

  private getOffres(): Observable<OffresListAnnonceResponse> {
    return this.annoncesServices.offres_list({
      cle_pa: this.clePa,
      cle_etab: this.cleEtab,
      cle_annonce: this.cleAnnonce || '',
    }).pipe(tap(({ offres }) => {
      _.sortBy(offres, ['numero_offre']);
      this.offresBS.next(offres);
    }));
  }

  private getMarches(): Observable<MarchesListAnnonceResponse> {
    return this.annoncesServices.marches_list({
      cle_pa: this.clePa,
      cle_etab: this.cleEtab,
      cle_annonce: this.cleAnnonce || '',
    }).pipe(tap(({ marches }) => {
      _.sortBy(marches, ['num']);
      this.marchesBS.next(marches);
    }));
  }

  private getAttributions(): Observable<AttributionsListAnnonceResponse> {
    return this.annoncesServices.attributions_list({
      cle_pa: this.clePa,
      cle_etab: this.cleEtab,
      cle_annonce: this.cleAnnonce || '',
    }).pipe(tap(({ Attributions }) => {
      _.sortBy(Attributions, ['num_attribution']);
      this.attributionsBS.next(Attributions);
    }));
  }

  // public getOrganisations(req: GetOrganisationRequest): Observable<OrganiastionListAnnonceResponse[]> {
  //   return this.annoncesServices.getOrganisations(req);
  // }

  // private getSupports(typeForm: string): Observable<SupportListResponse> {
  //   return this.annoncesServices.supportsList({
  //     cle_etab: this.cleEtab,
  //     cle_pa: this.clePa,
  //     type_formulaire: typeForm
  //   }).pipe(tap(({ jals }) => {
  //     this.supports.length = 0;
  //     this.supports.push(...jals);
  //   }));
  // }

  private callAllWS<T extends AnnonceDetails | EditAnnonceRequest | EditAnnonceResponse>(arg: T): Observable<T> {
    return forkJoin({
      // verifyRules: this.verifyRulesCall(),
      accounts: this.getAccountsCall(),
      lots: this.getLots(),
      soumissionnaires: this.getSoumissionnaires(),
      offres: this.getOffres(),
      marches: this.getMarches(),
      organisations: this.getOrganisations(),
      attributions: this.getAttributions(),
      // supports: this.getSupports(arg.type_formulaire),
    }).pipe(map(() => arg));

  }

  private callAllWSForModif<T extends AnnonceDetails | EditAnnonceRequest | EditAnnonceResponse>(arg: T): Observable<T> {
    return forkJoin({
      accounts: this.getAccountsCall(),
      lots: this.getLots(),
      soumissionnaires: this.getSoumissionnaires(),
      offres: this.getOffres(),
      marches: this.getMarches(),
      organisations: this.getOrganisations(),
      attributions: this.getAttributions(),
    }).pipe(map(() => arg));

  }

  // GET DATAS
  private creatGetterDatas<T>(key: string, callWS: Observable<T>): Observable<T> {
    return of(this.datas.get(key)).pipe(mergeMap(nuts => {
      if (nuts) return of(nuts);
      return callWS.pipe(tap(value => this.datas.set(key, value)));
    }));
  }

  public getNuts(): Observable<NutsItem[]> {
    return this.creatGetterDatas(
      'nuts',
      this.nutsService.getNuts().pipe(map(({ nuts_list }) => nuts_list))
    );
  }

  public getCountries(): Observable<CountryItem[]> {
    return this.creatGetterDatas(
      'countries',
      this.countryService.getCountry().pipe(map(({ countries }) => countries))
    );
  }

  public getDepartements(): Observable<Departement[]> {
    return this.creatGetterDatas(
      'departements',
      this.departementService.getDepartment().pipe(map(({ departments }) => departments))
    );
  }

  public getCurrencies(): Observable<CurrencyItem[]> {
    return this.creatGetterDatas(
      'currencies',
      this.annoncesServices.getCurrencies().pipe(map(({ currencies }) => currencies))
    );
  }

  public getMotsDescripteur(): Observable<CpvItem[]> {
    return this.creatGetterDatas(
      'descripteurs',
      this.motsDescripteurService.getMotsDescripteur().pipe(map(({ mots_descripteurs_list }) => mots_descripteurs_list))
    );
  }
}
