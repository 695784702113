import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Icons } from '@atline-shared/enums/icons.enum';
import * as _ from 'lodash';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  public isOnHover = false;

  @Input()
  icon?: Icons;

  @Input()
  class?: string;

  @Input()
  selected?: boolean;

  @Input()
  disabled? = false;

  @Input()
  size?: 'small' | 'medium' | 'large' | 'auto' | 'n2' | 'n' | 'text' | 'add' = 'auto';

  @Input()
  width?: string | number;

  @Input()
  height?: string | number;

  @Input()
  type?: 'button' | 'submit' | 'reset';

  @ViewChild('customContent')
  ngContentContainer!: ElementRef;

  @ViewChild('icon')
  iconRef!: ElementRef;

  @Output()
  readonly clickButton = new EventEmitter<MouseEvent>();

  constructor() { }

  ngOnInit(): void {
  }

  get hasInternalContent(): boolean {
    return this.ngContentContainer?.nativeElement.innerHTML || this.ngContentContainer?.nativeElement.children.length;
  }

  get customClass(): string {
    return this.class
      ? `${this.class} ${this.size} ${this.selected ? ' selected ' : ''} ${this.disabledValue ? 'disabled' : ''}`
      : `${this.size} ${this.selected ? ' selected ' : ''} ${this.disabledValue ? 'disabled' : ''}}`;
  }

  get customStyles(): any {
    let styles = {};
    // if (this.icon && !this.hasInternalContent)
      // styles = { ...styles, 'background-image': `url(/assets/icons/svg/${this.iconSvgName})` };

    if (this.width)
      styles = { ...styles, width: (typeof this.width) === 'string' ? this.width : `${this.width}px` };

    if (this.height)
      styles = { ...styles, width: (typeof this.height) === 'string' ? this.height : `${this.height}px` };

    return styles;
  }

  get customStylesContent(): any {
    if (this.hasInternalContent)
      return { 'background-image': `url(/assets/icons/svg/${this.iconSvgName})` };
    return {};
  }

  get iconSvgName(): string {
    return `${this.icon}${this.disabledValue ? '' : this.isOnHover || this.selected ? '_white' : ''}.svg`;
  }

  get disabledValue(): boolean {
    return _.isUndefined(this.disabled) ? false : this.disabled;
  }

  get idValue(): string {
    return `${this.disabledValue ? 'disabled' : ''}`;
  }

  click(): void {
    if (!this.disabled && !_.isUndefined(this.disabled)) {
      this.clickButton.emit();
    }
  }

  // get customStyleButton(): any {
  //   let style = {};
  //   if (this.isOnHover)
  //     return style = { fill: '#fff !important' }
  //   return style
  // }

}
