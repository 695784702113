import { environment } from './../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { DialogsService } from '../services/dialogs.service';
import { Debounce } from '../decorators/debounce.decorator';
import { HttpErrorEnum } from '@atline-shared/enums/httpError.enum';
import { AuthenticateService } from '../services/authenticate.service';
import { GlobalDataService } from '../services/data-service.service';

const blackListPath = ['atst/structure/attestations_obligatoires', 'logo_pa'];
const CODE_500 = 500;

@Injectable()
export class HttpResponseTimeLogger implements HttpInterceptor {


  constructor(
    private readonly dialogServices: DialogsService,
    private readonly authService: AuthenticateService,
    private readonly globalDataService: GlobalDataService
  ) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = req.clone({ headers: req.headers });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error.error.error === HttpErrorEnum.INVALID_TOKEN);


        if (error.error.error === HttpErrorEnum.INVALID_TOKEN) {
          this.authService.generateTokenForClient().subscribe({
            next: (res) => {
              this.authService.setSessionData(res);
              this.authService.setLocalData(res);
              this.globalDataService.setToken(res.access_token);
              next.handle(req);
              // s.unsubscribe();
            }
          });
        }


        switch (environment.env) {
          case 'dev':
            // this.matDialog.open(WsErrorDialogComponent, {
            //     data: { error: error.error.error_description }
            // });
            if (!_.find(blackListPath, (p) => `${environment.api}${p}` === `${error.url}`)) {
              // alert('--- WS error --- \n\n' + error.error.error_description);
              if (error.status !== CODE_500) {
                alert(error.url + '\n' + error.status + '\n' + error.error.error_description);
              } else {
                this.openDialogError(error);
              }
            }
            console.log(error);
            break;

          case 'loc':
            if (!_.find(blackListPath, (p) => `${environment.api}${p}` === `${error.url}`)) {
              // alert('--- WS error --- \n\n' + error.error.error_description);
              if (error.status !== CODE_500) {
                alert(error.url + '\n' + error.status + '\n' + error.error.error_description);
              } else {
                this.openDialogError(error);
              }
            }
            console.log(error);
            break;

          case 'ppprd':
            // if (!_.find(blackListPath, (p) => `${environment.api}${p}` === `${error.url}`)) {
            //   // alert('--- WS error --- \n\n' + error.error.error_description);
            //   if (error.status !== CODE_500) {
            //     alert(error.url + '\n' + error.error.error_description);
            //   } else {
            //     this.openDialogError(error);
            //   }
            // }
            console.log(error);
            break;

          case 'prd':
            // if (error.status === CODE_500) {
            //   this.dialogServices.createInfoDialog('', `APP.DIALOG.ERRORS.CODE_${error.status}`, 'APP.CLOSE', true);
            // }
            break;
          default:
            break;
        }
        return throwError(error);
      })


    );
  }

  @Debounce(200)
  private openDialogError(error: HttpErrorResponse): void {
    this.dialogServices.createInfoDialog('', `APP.DIALOG.ERRORS.CODE_${error.status}`, 'APP.CLOSE', true);
  }
}
