<app-dialog [dialogRef]="dialogRef">

  <ng-container header>
    {{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.HEADER' + (isSemContext ? '_SEM' : '') | translate }}
  </ng-container>

  <ng-container body>
    <ng-container *ngIf="isLoading; else contentTemplate">
      <app-loading-spinner style="margin: 20px;"></app-loading-spinner>
    </ng-container>
    <ng-template #contentTemplate>

      <ng-container *ngIf="isSemContext && !canUseSem; else elseTemplate">
        <p [innerHTML]="'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.SEM_INFO_1' | translate"></p>
        <p [innerHTML]="'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.SEM_INFO_2' | translate"></p>
      </ng-container>

      <ng-template #elseTemplate>
        <ng-container *ngIf="hasAtrributaires; else noAttributaireTemplate">

          <div class="lot" fxLayout="row" fxLayoutAlign="start center" *ngIf="data.hasLot">
            <span class="label text-bl">{{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.CHOOSE_BATCH' |
              translate}}</span>
            <mat-form-field appearance="outline">
              <mat-select [formControl]="selectedBatche">
                <mat-option *ngFor="let item of batches" [value]="item.cle">
                  {{item.libelle}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <section fxLayout="column" fxLayoutAlign="start start">
            <h2 class="full-width">
              {{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.HOLDERS' | translate}}
            </h2>
            <span class="small">{{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.UNSELECT' | translate}}</span>
            <app-holder-row *ngFor="let holder of attributaireSelected" [data]="data" [holder]="holder" (checkChange)="holder.isSelected = $event"
              [context]="data.context"></app-holder-row>
          </section>

        </ng-container>
        <ng-template #noAttributaireTemplate>
          <p class="text-center">{{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.NO_ATTRIBUTAIRE' | translate }}</p>
        </ng-template>

        <section fxLayout="column" fxLayoutAlign="start start" *ngIf="hasGroups">
          <h2 class="full-width">
            {{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.GROUPS' | translate}}
          </h2>
          <div class="groups-associated">

            <app-round-checkbox [checked]="duplicateGroup" (changeState)="duplicateGroup = $event">
              <span label>
                Dupliquer les groupes auxquels la consultation est rattachée
              </span>
            </app-round-checkbox>
            <br>
            <span class="small">Si vous souhaitez supprimer un groupe, veuillez cocher la case du groupe à effacer
              :</span>
            <div fxLayout="column" fxLayoutGap="10px">
              <ng-container *ngFor="let group of groups" class="group">
                <app-round-checkbox [disabled]="!duplicateGroup" [checked]="group.isSelected"
                  (changeState)="group.isSelected = $event">
                  <span label>
                    {{group.nom}}
                  </span>
                </app-round-checkbox>
              </ng-container>
            </div>
          </div>
        </section>

      </ng-template>
    </ng-template>
  </ng-container>
  <ng-container footer>
    <div fxLayout="column" fxLayoutAlign="end center" class="full-width">
      <app-button [size]="'text'" (click)="sendRequest()" *ngIf="displaySendBtn">
        {{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.' + (isSemContext ? 'CREATE_SEM' : 'CONSULTER_HOLDERS') | translate}}
      </app-button>

      <app-button [size]="'text'" *ngIf="displayGoToBtn">
        {{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.GO_TO_CONSULTATION' | translate}}
      </app-button>
    </div>
  </ng-container>
</app-dialog>