import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Icons } from '@atline-shared/enums/icons.enum';
import { DateHelper } from '@atline/core/helpers/date.helper';
import { ApiErrorResponse } from '@atline/core/models/api/responses/api.response';
import { AwardNotificationService } from '@atline/core/services/award-notification.service';
import * as _ from 'lodash';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-add-award-notification',
  templateUrl: './add-award-notification.component.html',
  styleUrls: ['./add-award-notification.component.scss']
})
export class AddAwardNotificationComponent implements OnInit {

  private today = new Date();
  private prevLimitDate = new Date(new Date().setMonth(new Date().getMonth() + 2));

  public limitDate = new FormControl(this.prevLimitDate, [Validators.required]);
  public notificationDate = new FormControl(this.today, [Validators.required]);
  public description = new FormControl('', [Validators.required]);
  public type = new FormControl(0, [Validators.required]);
  public form!: FormGroup;
  public file!: File;

  Icons = Icons


  constructor(
    public dialogRef: MatDialogRef<AddAwardNotificationComponent>,
    private readonly fb: FormBuilder,
    private readonly awardNotificationService: AwardNotificationService,
    private readonly dateHelper: DateHelper,
    @Inject(MAT_DIALOG_DATA) public data: {
      clePa: string,
      cleDce: string,
      cleEtab: string
    }
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      limitDate: this.limitDate,
      notificationDate: this.notificationDate,
      description: this.description,
      type: this.type
    });
  }

  /**
   * get file name
   * @return file name for selected file or file trougth by dialog data
   */
  get fileName(): string {
    return !_.isUndefined(this.file) ? this.file.name : '';
  }


  get canSend(): boolean {
    return this.form.valid && !_.isUndefined(this.file);
  }

  public save(): void {
    this.awardNotificationService.postAwardNotification({
      avis: this.file,
      cle_dce: this.data.cleDce,
      cle_etab: this.data.cleEtab,
      cle_pa: this.data.clePa,
      date_debut: this.dateHelper.formatdateForWSyyyymmdd(this.notificationDate.value, null, false),
      date_fin: this.dateHelper.formatdateForWSyyyymmdd(this.limitDate.value, null, false),
      description: this.description.value,
      type_avis: this.type.value,
    }).pipe(
      catchError((err) => {
        const apiError = err.error as ApiErrorResponse;
        if (apiError) {
        }
        return throwError(err);
      })
    ).subscribe(() => this.close());
  }

  /**
   * close dc dialaog
   */
  public close(): void {
    this.dialogRef.close({
      newAwardNotification: true
    });
  }

  public uploadFile(f: any): void {
    this.file = f.files[0];
  }

}
