<app-dialog [dialogRef]="dialogRef">

    <ng-container header>{{ data.title | translate }}</ng-container>
    <ng-container body>
        <form [formGroup]="formGroup">

            <div fxFlex="100" fxLayout="row" fxLayoutAlign="center">
                <label class="nameGroup text-bl" for="input-field">{{data.inputLabel | translate}}</label>
                <mat-form-field appearance="outline" class="full-width">
                    <input matInput type="text" name="input-field" formControlName="content" />
                </mat-form-field>
                <!-- <input matInput  matInput type="text"> -->
            </div>
            <!-- 
        <div class="bouton-ajout" fxLayoutAlign="center center">
            <button color="primary" mat-raised-button mat-dialog-close [mat-dialog-close]="false"
                (click)="validate()">{{'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.BUTTON_AJOUTER' | translate |
                uppercase}}</button>
        </div> -->

        </form>
    </ng-container>
    <ng-container footer>
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" class="info-dialog-actions" class="full-witdh">
            <button color="primary" mat-stroked-button mat-dialog-close [mat-dialog-close]="false"
                (click)="close()">{{'APP.CLOSE' | translate | uppercase}}</button>
            <button color="primary" mat-raised-button mat-dialog-close [mat-dialog-close]="false"
                (click)="validate()">{{'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.BUTTON_AJOUTER' | translate |
                uppercase}}</button>
        </div>
    </ng-container>

</app-dialog>