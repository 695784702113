import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { InfoDialogComponent } from '../../shared/components/info-dialog/info-dialog.component';
import { LoginComponent } from '../../shared/components/dialog/login/login.component';
import { CreateAccountComponent } from 'src/app/home/create-account/create-account.component';
import { DeletedConfirmationDialogComponent } from '@atline-shared/components/dialog/deleted-confirmation-dialog/deleted-confirmation-dialog.component';
// import { ApiErrorResponse } from '../models/api/responses/api.response';
import { GlobalDataService } from './data-service.service';
import { Router } from '@angular/router';
import {
  ConsultationDerivationComponent
} from '@atline-shared/components/dialog/consultation-derivation/consultation-derivation.component';
import { SendJalDialogComponent } from '@atline-shared/components/send-jal-dialog/send-jal-dialog.component';
import { ConsultationHolderComponent } from '@atline-shared/components/dialog/consultation-holder/consultation-holder.component';
import { AnnuaireAliasComponent } from '@atline-shared/components/dialog/annuaire-alias/annuaire-alias.component';
import { SessionExpiredComponent } from '@atline-shared/components/dialog/session-expired/session-expired.component';
import { ConfirmePublicationComponent } from '@atline-shared/components/dialog/confirme-publication/confirme-publication.component';
import { AcceptElrarCguComponent } from '@atline-shared/components/dialog/accept-elrar-cgu/accept-elrar-cgu.component';
import { AcceptElrarItem } from '../models/acceptElrarItem.model';
import { LoaderPublishComponent } from '@atline-shared/components/loaderPublish/loaderPublish.component';
import { TypeConfirmDialog } from '@atline-shared/enums/typeConfirmDialog.enum';
import { CreateAnnonceComponent } from '@atline-shared/components/dialog/create-annonce/create-annonce.component';
import * as _ from 'lodash';
import { CguComponent } from '@atline-shared/components/dialog/cgu/cgu.component';
import { AttributairesComponent } from '@atline-shared/components/dialog/attributaires/attributaires.component';
import { EssentialDataTitulaireItem } from '../models/essentialDataItem.model';
import { DictionnaryLotDialogComponent } from '@atline-shared/components/dialog/dictionnary-lot-dialog/dictionnary-lot-dialog.component';
import { TypeLotDictionnary } from '@atline/core/models/dictionnaryLotItem.model';
import { UsersDialogComponent } from '@atline-shared/components/dialog/users-dialog/users-dialog.component';
import { AffectedUserItem } from '../models/userItem.model';
import { EntrepriseRetraitComponent } from '@atline-shared/components/dialog/entreprise-retrait/entreprise-retrait.component';
import { ConnectedAnonymousComponent } from '@atline/marches-securises/connected-anonymous/connected-anonymous.component';
import { ConfirmationDialogComponent } from '@atline-shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { CreateGroupComponent } from '@atline-shared/components/dialog/create-group/create-group.component';
import { ModifyGroupComponent } from '@atline-shared/components/dialog/modify-group/modify-group.component';
import { AdministrationGroupItem } from '../models/administrationGroupItem.model';
import { RightsProcedureDialogComponent } from '@atline-shared/components/dialog/rights-procedure-dialog/rights-procedure-dialog.component';
import { ConsultationDetailItem } from '../models/consultation/ConsultationDetailItem.model';
import { BooleanValue } from '../types/boleanMs.type';
import { ConsultationOeItem } from '../models/consultation/ConsultationOeItem.model';
import { ConsultationPaItem } from '../models/consultation/ConsultationPaItem.model';
import { InputFieldDialogComponent } from '@atline-shared/components/dialog/input-field-dialog/input-field-dialog.component';
import { LegalNoticeComponent } from '@atline-shared/components/dialog/legal-notice/legal-notice.component';
import { RoutingService } from './routing.service';
import { PaUserComponent } from '@atline-shared/components/pa-user/pa-user.component';
import { AdminUserItem } from '../models/adminUser.model';
import { AddUserComponent } from '@atline-shared/components/add-user/add-user.component';
import { AddAwardNotificationComponent } from '@atline-shared/components/marche-pa/award-notification/add-award-notification/add-award-notification.component';
import { EditEmailComponent } from '@atline-shared/components/dialog/edit-email/edit-email.component';
import { EmailPaItem } from '../models/PaEmailItem.model';

type CallBack<T = any, R = any> = (result: T) => R;
type ParamLogin = CallBack | { callback?: CallBack, redirection?: any[] } | undefined;
type ConfirmDialogParam = {
  title?: TypeConfirmDialog | string,
  description: string,
  callback?: (result: any) => any,
  data?: { [name: string]: string | number }
};

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  public loaderRef: MatDialogRef<LoaderComponent> | undefined;
  public publishLoaderRef: MatDialogRef<LoaderPublishComponent> | undefined;
  public infoDialog: MatDialogRef<InfoDialogComponent> | undefined;
  public loginDialog: MatDialogRef<LoginComponent> | undefined;
  public signupDialog: MatDialogRef<CreateAccountComponent> | undefined;
  public confirmationDialog: MatDialogRef<DeletedConfirmationDialogComponent> | undefined;
  public deleteConfig: MatDialogRef<ConfirmationDialogComponent> | undefined;
  public publishConfirmationDialog: MatDialogRef<ConfirmePublicationComponent> | undefined;
  public sendToJalDialog: MatDialogRef<SendJalDialogComponent> | undefined;
  // formSelectDialog: MatDialogRef<SealFormSelectComponent> | undefined;
  public dialogUsers: MatDialogRef<UsersDialogComponent> | undefined;
  public derivationConsultationDialog: MatDialogRef<ConsultationDerivationComponent> | undefined;
  public holderDialog: MatDialogRef<ConsultationHolderComponent> | undefined;
  public annuaireAliasDialog: MatDialogRef<AnnuaireAliasComponent> | undefined;
  public sessionExpiredDialog: MatDialogRef<SessionExpiredComponent> | undefined;
  public cguDepotDialog: MatDialogRef<CguComponent> | undefined;
  public cguElrarDialog: MatDialogRef<AcceptElrarCguComponent> | undefined;
  public createAnnonceDialog: MatDialogRef<CreateAnnonceComponent> | undefined;
  public attributaireDialog: MatDialogRef<AttributairesComponent> | undefined;
  public rightsDialog: MatDialogRef<RightsProcedureDialogComponent> | undefined;
  public dictionnaryLotDialog: MatDialogRef<DictionnaryLotDialogComponent> | undefined;
  public downloadAsAnonymous: MatDialogRef<ConnectedAnonymousComponent> | undefined;
  public createGroupConfirmationDialog: MatDialogRef<CreateGroupComponent> | undefined;
  public editGroupConfirmationDialog: MatDialogRef<ModifyGroupComponent> | undefined;
  public inputFieldDialog: MatDialogRef<InputFieldDialogComponent> | undefined;
  public legalNoticenDialog: MatDialogRef<LegalNoticeComponent> | undefined;
  public paUserDialog: MatDialogRef<PaUserComponent> | undefined;
  public addEditUserDialog: MatDialogRef<AddUserComponent> | undefined;
  public awardNotificationDialog: MatDialogRef<AddAwardNotificationComponent> | undefined;
  public editMailNotificationRejetDialog: MatDialogRef<EditEmailComponent> | undefined;

  constructor(
    private readonly dialog: MatDialog,
    private globalData: GlobalDataService,
    private router: Router,
    private readonly routingService: RoutingService
  ) { }

  createEntrepriseRetraitDialog:
    | MatDialogRef<EntrepriseRetraitComponent>
    | undefined;

  /**
   * Open a spinner loading
   * Add config: Config = {width: '20vw'} and this.loaderRef.updateSize(config.width); if you want to make the width configurable
   * @param title title of the loader, can be empty to show no title
   * @param config with specific width of the loader
   * @param callback to run after the close of the loader
   */
  openLoader(
    title: string = '',
    callback?: CallBack<undefined, void>
  ): MatDialogRef<LoaderComponent> {
    if (this.loaderRef) {
      this.closeLoader();
    }
    this.loaderRef = this.dialog.open(LoaderComponent, {
      disableClose: true,
      panelClass: 'spinner',
    });
    this.loaderRef.componentInstance.title = title;
    if (callback) {
      this.loaderRef.afterClosed().subscribe(callback);
    }
    return this.loaderRef;
  }

  /**
   * Close the loader
   */
  closeLoader(): void {
    if (this.loaderRef) {
      this.loaderRef.close();
      this.loaderRef = undefined;
    }
  }

  openAwardNotificationDialog(
    consultation: ConsultationPaItem,
    callback?: CallBack
  ): MatDialogRef<AddAwardNotificationComponent> {
    this.awardNotificationDialog = this.dialog.open(AddAwardNotificationComponent,
      {
        disableClose: true,
        panelClass: 'dc-dialog',
        position: {
          top: '10%'
        },
        maxWidth: '60vw',
        width: '100%',
        data: {
          cleEtab: consultation.cleEtab,
          cleDce: consultation.cleDce,
          clePa: consultation.clePa
        }
      });

    if (callback) this.awardNotificationDialog.afterClosed().subscribe(callback);
    return this.awardNotificationDialog;
  }

  closeAddAwardNotificationDialog() {
    if (this.awardNotificationDialog)
      this.awardNotificationDialog.close()
    this.awardNotificationDialog = undefined;
  }

  openPublishLoader(
    data: { cleDce: string, cleEtab: string, clePa: string },
    title = '',
    callback?: CallBack<undefined, void>
  ): MatDialogRef<LoaderPublishComponent> {
    if (this.loaderRef) {
      this.closeLoader();
    }
    this.publishLoaderRef = this.dialog.open(LoaderPublishComponent, {
      disableClose: true,
      panelClass: 'spinner',
      data: { data }
    });
    this.publishLoaderRef.componentInstance.title = title;
    if (callback) {
      this.publishLoaderRef.afterClosed().subscribe(callback);
    }
    return this.publishLoaderRef;
  }

  /**
   * Close the loader
   */
  closePublishLoader(): void {
    if (this.publishLoaderRef) {
      this.publishLoaderRef.close();
      this.publishLoaderRef = undefined;
    }
  }

  /**
   *
   * @param type open confirmation dialog
   * @param callback callback
   * @returns Mat dialog
   */
  openConfirmationDialog(params: ConfirmDialogParam): MatDialogRef<DeletedConfirmationDialogComponent> {
    const { title, description, callback, data } = params;
    if (this.confirmationDialog) this.closeLoader();
    this.confirmationDialog = this.dialog.open(DeletedConfirmationDialogComponent, {
      disableClose: true,
      panelClass: 'spinner',
      data: { description, title, informations: data }
    });
    if (callback) this.confirmationDialog.afterClosed().subscribe(callback);
    return this.confirmationDialog;
  }

  /**
   *
   * @param type open create group dialog
   * @param callback callback
   * @returns Mat dialog
   */
  openCreationGroupDialog(params: ConfirmDialogParam, callback?: CallBack): void {
    const { title, description } = params;

    this.createGroupConfirmationDialog = this.dialog.open(CreateGroupComponent, {
      disableClose: true,
      panelClass: 'spinner',
      maxWidth: '70vw',
      minWidth: '50vw',
      data: { title, description }
    });

    if (callback)
      this.createGroupConfirmationDialog.afterClosed().subscribe(callback);
  }

  openEditGroupDialog(params: ConfirmDialogParam): MatDialogRef<ModifyGroupComponent> {
    const { title, description, callback, data } = params;

    this.editGroupConfirmationDialog = this.dialog.open(ModifyGroupComponent, {
      disableClose: true,
      panelClass: 'spinner',
      maxWidth: '70vw',
      minWidth: '50vw',
      data: { title, description, informations: data }
    });

    if (callback)
      this.editGroupConfirmationDialog.afterClosed().subscribe(callback);
    return this.editGroupConfirmationDialog;
  }

  closeCreationGroupDialog(data: any): void {
    if (this.createGroupConfirmationDialog) {
      this.createGroupConfirmationDialog.close(data);
      this.createGroupConfirmationDialog = undefined;
    }
  }

  openInputFieldDialog(inputLabel: string, title: string, callback: (result: any) => any,): MatDialogRef<InputFieldDialogComponent> {
    this.inputFieldDialog = this.dialog.open(InputFieldDialogComponent, {
      disableClose: true,
      panelClass: 'spinner',
      maxWidth: '70vw',
      minWidth: '50vw',
      data: { inputLabel, title }
    });

    if (callback)
      this.inputFieldDialog.afterClosed().subscribe(callback);
    return this.inputFieldDialog;
  }

  closeInputFieldDialog(data: any): void {
    if (this.inputFieldDialog) {
      this.inputFieldDialog.close(data);
      this.inputFieldDialog = undefined;
    }
  }

  closeModifyGroupDialog(data: any): void {
    if (this.editGroupConfirmationDialog) {
      this.editGroupConfirmationDialog.close(data);
      this.editGroupConfirmationDialog = undefined;
    }
  }

  /**
   * Close the confirmation dialog
   */
  closeConfirmation(): void {
    if (this.confirmationDialog) {
      this.confirmationDialog.close();
      this.confirmationDialog = undefined;
    }
  }

  /**
   *
   * @param type open attributaires dialog
   * @param callback callback
   * @returns Mat dialog
   */
  openAttributaireDialog(params: EssentialDataTitulaireItem[], callback?: CallBack): MatDialogRef<AttributairesComponent> {
    if (this.attributaireDialog) this.closeLoader();
    this.attributaireDialog = this.dialog.open(AttributairesComponent, {
      disableClose: true,
      panelClass: 'spinner',
      width: '100%',
      maxWidth: '600px',
      data: params
    });
    if (callback) this.attributaireDialog.afterClosed().subscribe(callback);
    return this.attributaireDialog;
  }
  /**
   *
   * @param type open rights dialog
   * @param callback callback
   * @returns Mat dialog
   */
  openRightsDialog(group: AdministrationGroupItem, callback?: CallBack): MatDialogRef<RightsProcedureDialogComponent> {
    if (this.confirmationDialog) this.closeLoader();
    this.rightsDialog = this.dialog.open(RightsProcedureDialogComponent, {
      disableClose: false,
      panelClass: 'panel',
      maxWidth: '100vw',
      minWidth: '80vw',
      height: '99%',
      data: group
    });
    if (callback) this.rightsDialog.afterClosed().subscribe(callback);
    return this.rightsDialog;
  }

  openGroupUsers(users: AffectedUserItem[]): MatDialogRef<UsersDialogComponent> {
    this.dialogUsers = this.dialog.open(UsersDialogComponent, {
      data: { users },
      disableClose: true,
      maxWidth: '100vw',
      minWidth: '50vw'
    });
    this.dialogUsers.afterClosed().subscribe(() => {
      this.dialogUsers = undefined;
    })
    return this.dialogUsers;
  }
  closeGroupUsers(): void {
    this.dialogUsers?.close();
    this.dialogUsers = undefined;
  }

  /**
   * Close the confirmation dialog
   */
  closeAttrbutaires(): void {
    if (this.attributaireDialog) {
      this.attributaireDialog.close();
      this.attributaireDialog = undefined;
    }
  }

  closeRightsDialog(): void {
    if (this.rightsDialog) {
      this.rightsDialog.close();
      this.rightsDialog = undefined;
    }
  }

  openAnnaireAliasDialog(
    clePa: string,
    cleEtab: string,
    callback?: CallBack,
  ): MatDialogRef<AnnuaireAliasComponent> {
    this.annuaireAliasDialog = this.dialog.open(AnnuaireAliasComponent, {
      disableClose: true,
      // panelClass: 'spinner',
      width: '100%',
      maxWidth: '600px',
      height: 'fit-content',
      data: {
        cleEtab,
        clePa
      }
    });

    if (callback)
      this.annuaireAliasDialog.afterClosed().subscribe(callback);
    return this.annuaireAliasDialog;
  }

  closeAnnuaireAliasDialog(): void {
    if (this.annuaireAliasDialog) {
      this.annuaireAliasDialog.close();
      this.annuaireAliasDialog = undefined;
    }
  }


  openSessionExpiredDialog(
    callback?: CallBack,
  ): MatDialogRef<SessionExpiredComponent> {
    if (!this.sessionExpiredDialog)
      this.sessionExpiredDialog = this.dialog.open(SessionExpiredComponent, {
        disableClose: true,
        // panelClass: 'spinner',
        width: '100%',
        maxWidth: '600px',
        height: 'fit-content',
      });

    if (callback)
      this.sessionExpiredDialog.afterClosed().subscribe(callback);
    return this.sessionExpiredDialog;
  }

  closeSessionExpiredDialog(): void {
    if (this.sessionExpiredDialog) {
      this.sessionExpiredDialog.close();
      this.sessionExpiredDialog = undefined;
    }
  }

  openCguElrarDialog(
    clePa: string,
    callback?: CallBack<AcceptElrarItem>,
  ): MatDialogRef<AcceptElrarCguComponent> {
    this.cguElrarDialog = this.dialog.open(AcceptElrarCguComponent, {
      disableClose: true,
      width: '100%',
      maxWidth: '600px',
      height: 'fit-content',
      data: {
        clePa
      }
    });

    if (callback)
      this.cguElrarDialog.afterClosed().subscribe(callback);
    return this.cguElrarDialog;
  }

  closeCguElrarDialog(): void {
    if (this.cguElrarDialog) {
      this.cguElrarDialog.close();
      this.cguElrarDialog = undefined;
    }
  }


  openCguDepotDialog(
    isDume: BooleanValue,
    callback?: CallBack,
  ): MatDialogRef<CguComponent> {
    // if (!this.cguDepotDialog)
    this.cguDepotDialog = this.dialog.open(CguComponent, {
      disableClose: true,
      panelClass: 'padding-dialog-0',
      width: '100%',
      maxWidth: '85vw',
      height: 'fit-content',
      data: {
        isDume
      }
    });

    if (callback)
      this.cguDepotDialog.afterClosed().subscribe(callback);
    return this.cguDepotDialog;
  }

  closeCguDepotDialog(value: boolean | undefined): void {

    if (this.cguDepotDialog) {
      this.cguDepotDialog.close(value);
      this.cguDepotDialog = undefined;
    }
  }

  openDerivationDialog(
    id: string,
    title: string,
    isRestreinte: BooleanValue,
    hasLot: boolean,
    hasGroups: boolean,
    hasDce: boolean,
    callback?: CallBack,
  ): MatDialogRef<ConsultationDerivationComponent> {
    this.derivationConsultationDialog = this.dialog.open(ConsultationDerivationComponent, {
      disableClose: true,
      // panelClass: 'spinner',
      width: '100%',
      maxWidth: '600px',
      height: 'fit-content',
      data: {
        hasLot,
        isRestreinte,
        id,
        title,
        hasGroups,
        hasDce
      }
    });

    if (callback)
      this.derivationConsultationDialog.afterClosed().subscribe(callback);
    return this.derivationConsultationDialog;
  }

  closeDerivationCOnsultationDialog(): void {
    if (this.derivationConsultationDialog) {
      this.derivationConsultationDialog.close();
      this.derivationConsultationDialog = undefined;
    }
  }

  openHoldersDialog(
    cleDce: string,
    cleEtab: string,
    clePa: string,
    hasLot: boolean,
    groups: Array<any>,
    context: 'inform' | 'sem' = 'inform',
    // hasDce: boolean,
    callback?: CallBack,
  ): MatDialogRef<ConsultationHolderComponent> {
    this.holderDialog = this.dialog.open(ConsultationHolderComponent, {
      panelClass: 'holders',
      width: '100%',
      maxWidth: '1000px',
      height: 'fit-content',
      data: {
        cleDce,
        cleEtab,
        clePa,
        hasLot,
        groups,
        context
      }
    });

    if (callback)
      this.holderDialog.afterClosed().subscribe(callback);
    return this.holderDialog;
  }

  closeHolderDialog(): void {
    if (this.holderDialog) {
      this.holderDialog.close();
      this.holderDialog = undefined;
    }
  }


  // /**
  //  *
  //  * @param type open confirmation dialog
  //  * @param callback callback
  //  * @returns Mat dialog
  //  */
  // openSealForm(
  //   callback?: (result: any) => any
  // ): MatDialogRef<SealFormSelectComponent> {
  //   if (this.confirmationDialog) {
  //     this.closeLoader();
  //   }
  //   this.formSelectDialog = this.dialog.open(SealFormSelectComponent, {
  //     disableClose: true,
  //     panelClass: 'spinner',
  //     width: '40vw'
  //   });
  //   // this.confirmationDialog.componentInstance.type = type;
  //   // this.confirmationDialog.componentInstance.description = description;
  //   if (callback) {
  //     this.formSelectDialog.afterClosed().subscribe(callback);
  //   }
  //   return this.formSelectDialog;
  // }







  /**
   * Create an info dialog with title, description and callback
   * @param title Title of the dialog
   * @param message Description's message
   * @param buttonLabel the label title of the button (i.e ok or cancel)
   * @param callback actions to call after creating the dialog
   */
  createInfoDialog(
    title: string,
    message: string,
    buttonLabel: string,
    dialogClose = true,
    callback?: CallBack,
    data?: { [name: string]: string | number }
  ): MatDialogRef<InfoDialogComponent> {
    this.infoDialog = this.dialog.open(InfoDialogComponent, {
      disableClose: dialogClose,
      panelClass: 'info-dialog',
      minWidth: '20vw',
      data: {
        title,
        description: message,
        actionTitle: buttonLabel,
        informations: data,
      },
    });

    if (callback) { this.infoDialog.afterClosed().subscribe(callback); }
    return this.infoDialog;
  }

  /**
   * Open Login Component
   * @param callback actions to call after close of dialog
   */
  openLoginDialog(param?: ParamLogin): void {
    const callback = _.isFunction(param) ? param : param?.callback;
    const redirection = !_.isFunction(param) ? param?.redirection : undefined;

    let loaderDialog: MatDialogRef<LoaderComponent, any>;
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: 'login-dialog',
      maxWidth: '95vw',
      position: { top: '64px' },
      data: {
        before: () => loaderDialog = this.openLoader('APP.ACTION.LOADING'),
        after: (result: any) => {
          loaderDialog.close();
          if (result) {
            this.globalData.isPa.subscribe(isPa => {
              if (!_.isNil(redirection)) this.router.navigate(redirection);
              else if (isPa) this.router.navigate([this.routingService.firstFragment, 'pa']);
              else this.router.navigate([this.routingService.firstFragment]);
            });
          } else {
            loaderDialog.close();
            this.dialog.open(LoginComponent, {
              panelClass: 'login-dialog',
              maxWidth: '95vw',
              position: { top: '64px' },
            });
          }
        }
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (callback) {
        callback(result);
      }

      if (result === 'signup') {
        this.openSignUpDialog();
      }
    });
  }


  /**
   * Open CreateAccount Component
   * @param callback actions to call after close of dialog
   */
  // openSignUpDialog(callback?: (result: any) => any): void {
  openSignUpDialog(): void {
    // const dialogRef = this.dialog.open(CreateAccountComponent, {
    //   panelClass: 'signup-dialog',
    //   maxWidth: '95vw',
    //   position: {
    //     top: '64px',
    //   },
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   if (callback) {
    //     callback(result);
    //   }
    // });
    window.open('https://marches-securises.fr/entreprise/?module=inscription', '_blank')?.focus();
  }

  /**
   * Open dialog to confirm publication if send Oe
   * @param consData consultation data
   * @param callback callback
   */
  openPublishConfirmation(
    consData: ConsultationDetailItem,
    daysBeforeDeadline: number,
    alreadyPublished: BooleanValue,
    infoProc: { [id: string]: string },
    callback?: (result: { state: boolean, subject: string, text: string, sendEmails: boolean, shareOnQR: boolean }
    ) => any): MatDialogRef<ConfirmePublicationComponent, any> {
    this.publishConfirmationDialog = this.dialog.open(ConfirmePublicationComponent, {
      position: {
        top: '100px'
      },
      width: '80vw',
      disableClose: true,
      panelClass: 'app-dialog',
      data: {
        infoProc,
        consId: consData.id,
        publicationDate: consData.datePublication.dateFormated,
        closureDate: consData.dateCloture.dateFormated,
        object: consData.objet,
        prodedure: consData.procedureLabel,
        reciever: consData.emails,
        daysBeforeDeadline: daysBeforeDeadline,
        nbDceRetrieved: 0,
        refInterne: consData.refInterne,
        paName: consData.denominationPa,
        alreadyPublished,
        retraits: {
          papier: consData.registres.retrait.paper,
          electronic: consData.registres.retrait.electronic
        }
      }
    });

    this.publishConfirmationDialog.afterClosed().subscribe((result) => {
      if (callback) callback(result);
    });
    return this.publishConfirmationDialog;
  }


  /**
   * Close the publish confirmation dialog
   */
  closePublishConfirmation(): void {
    if (this.publishConfirmationDialog) {
      this.publishConfirmationDialog.close();
      this.publishConfirmationDialog = undefined;
    }
  }



  /**
   * Open dialog to confirm publication if send Oe
   * @param callback callback
   */
  openSendToJalDialog(callback?: (result: string[]) => any): void {
    this.sendToJalDialog = this.dialog.open(SendJalDialogComponent,
      {
        position: {
          top: '100px'
        },
        width: '80vw',
        maxWidth: '800px',
        disableClose: true,
        panelClass: 'spinner',
      });

    this.sendToJalDialog.afterClosed().subscribe((result) => {
      if (callback) {
        callback(result);
      }
    });
  }


  /**
   * Close the publish confirmation dialog
   */
  closeSendToJalDialog(): void {
    if (this.sendToJalDialog) {
      this.sendToJalDialog.close();
      this.sendToJalDialog = undefined;
    }
  }


  openDownloadAnonymousDialog(consultation: ConsultationOeItem, codeRestreint: string, callback?: (result: boolean) => any): void {
    this.downloadAsAnonymous = this.dialog.open(ConnectedAnonymousComponent,
      {
        disableClose: false,
        panelClass: 'dce-dialog',
        width: '80vw',
        maxHeight: '90vh',
        data: {
          cle_etab: consultation.cleEtab,
          cle_dce: consultation.cleDce,
          cle_dc: consultation.pieces.dc,
          codeRestreint
        }
      }
    );
    this.downloadAsAnonymous.afterClosed().subscribe((result) => {
      if (callback) {
        callback(result);
      }
    });
  }

  closeDownloadAnonymousDialog(): void {
    if (this.downloadAsAnonymous) {
      this.downloadAsAnonymous.close();
      this.downloadAsAnonymous = undefined;
    }
  }

  /**
   * Open a dialog form for create a annonce
   */
  openCreateAnnonce(): MatDialogRef<CreateAnnonceComponent> {
    this.createAnnonceDialog = this.dialog.open(CreateAnnonceComponent, {
      width: '40vw',
      minWidth: '400px',
    });
    return this.createAnnonceDialog;
  }

  /**
   * Close a dialog form for create a annonce
   */
  closeCreateAnnonce(): void {
    this.createAnnonceDialog?.close();
  }

  openUserPa(user: AdminUserItem): MatDialogRef<PaUserComponent> {
    if (_.isUndefined(this.paUserDialog))
      this.paUserDialog = this.dialog.open(PaUserComponent, {
        width: '60vw',
        maxWidth: '100%',
        minWidth: '400px',
        data: {
          user
        }
      });
    this.paUserDialog.afterClosed().subscribe(() => this.paUserDialog = undefined)
    return this.paUserDialog;
  }

  closeUserPa(): void {
    if (!_.isUndefined(this.paUserDialog))
      this.paUserDialog.close();
    this.paUserDialog = undefined;
  }

  openAddEditUser(user: AdminUserItem | null): MatDialogRef<AddUserComponent> {
    if (_.isUndefined(this.addEditUserDialog))
      this.addEditUserDialog = this.dialog.open(AddUserComponent, {
        width: '80vw',
        // maxWidth: '100%',
        maxHeight: '90vh',
        minWidth: '400px',
        data: {
          user
        }
      });
    this.addEditUserDialog.afterClosed().subscribe(() => this.addEditUserDialog = undefined)
    return this.addEditUserDialog;
  }

  closeAddEditUser(): void {
    if (!_.isUndefined(this.addEditUserDialog))
      this.addEditUserDialog.close();
    this.addEditUserDialog = undefined;
  }

  /**
  * Open a dialog form for retrait entreprise - guichet entreprise
  */
  openRetraitEntrepriseGuichet(
    consultation: ConsultationPaItem,
    callback?: (result: any) => void
  ): MatDialogRef<EntrepriseRetraitComponent> {
    if (!this.dialog) {
      throw new Error('dialog is undefined');
    }

    this.createEntrepriseRetraitDialog = this.dialog.open(
      EntrepriseRetraitComponent,
      {
        width: '60vw',
        minWidth: '600px',
        height: '40vw',
        minHeight: '400px',
        maxHeight: '400px',
        data: { consultation },
      }
    );

    if (!this.createEntrepriseRetraitDialog) {
      throw new Error('createEntrepriseRetraitDialog is undefined');
    }

    this.createEntrepriseRetraitDialog.afterClosed().subscribe((result) => {
      if (callback) {
        callback(result);
      }
    });

    return this.createEntrepriseRetraitDialog;
  }

  openDictionnaryLot(clePa: string, type?: TypeLotDictionnary): MatDialogRef<DictionnaryLotDialogComponent> {
    this.dictionnaryLotDialog = this.dialog.open(DictionnaryLotDialogComponent, {
      width: '40vw',
      minWidth: '300px',
      data: { type, clePa }
    });
    return this.dictionnaryLotDialog;
  }

  closeDictionnary(): void {
    this.dictionnaryLotDialog?.close();
  }

  openLegalNotice(): MatDialogRef<LegalNoticeComponent> {
    this.legalNoticenDialog = this.dialog.open(LegalNoticeComponent, {
      width: '40vw',
      minWidth: '300px'
    });
    return this.legalNoticenDialog;
  }

  closeLegalNotice(): void {
    if (this.legalNoticenDialog) {
      this.legalNoticenDialog.close();
      this.legalNoticenDialog = undefined;
    }
  }

  openEditEmailNotificationRejet(
    data: { emails: EmailPaItem[], state: string, cleDce: string, cle: string, cleEtab: string },
    callback?: (result: any) => void
  ) {
    this.editMailNotificationRejetDialog = this.dialog.open(EditEmailComponent,
      {
        panelClass: 'dc-dialog',
        width: '30vw',
        position: {
          top: '20%'
        },
        data
      });

    this.editMailNotificationRejetDialog.afterClosed().subscribe((result) => {
      if (callback) {
        callback(result);
      }
    });
  }

  closeEditEmailNotificationRejet(): void {
    if (this.editMailNotificationRejetDialog) {
      this.editMailNotificationRejetDialog.close();
      this.editMailNotificationRejetDialog = undefined;
    }
  }

}

