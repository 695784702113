export class AffaireItem {
  // tslint:disable:variable-name

  public id!: number;
  public cle = '';
  public cle_pa = '';
  public objet = '';
  public date_creation!: number;
  public date_creation_f = '';
  public nb_dce!: number;
  public nb_projet!: number;
  public etat_affaire!: number;
  public cloture!: boolean | number;

  // tslint:enable:variable-name
}
