import * as _ from 'lodash';

export class CpvItem {
    // tslint:disable:variable-name

    /**
     * id of cpv code
     */
    public id = 0;

    /**
     * code number of cpv
     */
    public code_cpv = '';

    /**
     * label for cpv code
     */
    public label_cpv = '';

    public typeCpv = '';
    // tslint:enable:variable-name
}

export function isCpvItem(value: any): value is CpvItem {
  return value instanceof CpvItem ||
    _.isObject(value) ? ((): boolean => {
      const keys = Object.keys(value);
      let count = 0;
      for (const key of Object.keys(new CpvItem())) {        
        if (!keys.includes(key)) { return false; }
        count++;
      }
      return keys.length === count;
    })() : false;
}
