export const environment = {
  env: 'prdv3',
  production: true,
  version: '3.0.228.298',
  lang: 'FR',
  hotline: '+33488050770',
  api: 'https://wsp.atline.fr/',
  redirectUrl : 'https://dev.atline-services.com/',
  clientId : 'atline-services',
  clientSecret : '22360C1B138EA4EA935F1B28FB1B16CB',
  grantTypeClientCredentials: 'client_credentials',
  msFrLinkRedirection: 'https://www.marches-securises.fr/entreprise/?module=sso|connecter_utilisateur_s&access_token=',
  originPlateforme: '2',
  features: {},
  mediaURL: '',
  atlineFormation: 'https://www.atline-formations.fr/',
  mailCse: 'cse@atline.fr',
  activation: {
    pa: true
  },
  sso: {
    activation: {
      microsoft: false
    },
    microsoft: {
      clientId: '7b503b3f-fab3-4d20-ab33-53ebf464d549',
      clientSecret: 'tKy8Q~-oKH7c3Tg1bEL-D4IAehdKEURTjqdfIasL',
      response: {
        code: 'code',
        mode: 'query'
      },
      scope: 'User.Read',
      state: '1234',
      redirectUri: 'https://dev.atline-services.com/redirect-sso'
    }
  },
  domain: {
    ms: 'v3.marches-securises.fr',
    local: 'localhost',
    atswww: 'www.atline-services.com',
    ats: 'atline-services.com',
    atsPreprod: 'preprod.atline-services.com',
    dev: 'dev.atline-services.com'
  },
  easyOpen: {
    url: 'https://eop.atline-services.com/easyopen',
    api: 'https://apieasyopen.atline-services.com/redirect'
  },
  portalRessouces: 'https://landings.corporate.atline.fr/60103141b95cee1301d980a6/HZjVIO5pQTCqjlatAaAHkg/landing.html',
};
