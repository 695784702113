<app-dialog [dialogRef]="dialogRef">

  <div header> {{'APP.MARCHES_SECURISES.DC.EDIT_EMAILS' | translate | uppercase}}</div>

  <div body>
    <form [formGroup]="form">

      <div class="form-elt form-content" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
        <div class="input-label">
          {{'APP.MARCHES_SECURISES.DC.EMAILS_LABEL' | translate}}
        </div>

        <div class="input-elt">
          <mat-form-field class="full-width form-field" appearance="outline">
            <input matInput formControlName="email">
          </mat-form-field>
          <mat-error *ngIf="email.invalid && email.value.length > 3">{{'APP.MARCHES_SECURISES.DC.INVALID_EMAIL' |
            translate}}</mat-error>
        </div>
        <app-button [size]="'add'" [icon]="icons.Plus" (clickButton)="addEmail()" *ngIf="!emailAddingLoading; else loadingAddEmailTemplate"></app-button>
        <ng-template #loadingAddEmailTemplate>
          <mat-spinner matSuffix style="margin-right: 5px;" mode="indeterminate" [diameter]="25"></mat-spinner>
        </ng-template>
      </div>



      <div class="emails" fxLayout="column" *ngIf="data.emails.length !== 0">
        <mat-divider horizontal></mat-divider>
        <div *ngFor="let email of emails" fxLayout="row" fxLayoutAlign="start center">
          <ng-container *ngIf="email.email !== ''">
            <div class="email" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="65">
              <div fxLayout="row">
                <div class="data">
                  {{email.email}}
                </div>
                <div class="icon-bg certified-email" *ngIf="email.is_certified"
                  title="{{'APP.MARCHES_SECURISES.DC.CERTIFIED_EMAIL' | translate:{email:email.email} }}"></div>
              </div>
              <button mat-button class="bin icon-bg img-bg" (click)="removeEmail(email.email)"></button>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </div>

  <div footer>

  </div>


</app-dialog>