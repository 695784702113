import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AvenantDeleteRequest,
  AvenantEditRequest,
  EditEssentialDataRequest,
  EssentialDataOeListRequest,
  OeDeEditRequest,
  PublishDeRequest,
  RemoveOeDeRequest
} from '../models/api/requests/essentialData.request';
import { RequestForPaWithDCE } from '../models/api/requests/requestForPa.request';
import { DceEssentialDataList } from '../models/api/responses/essential-data-list.response';
import { AvenantEditReponse, DceEssentialData, OeDeEditReponse, PublishDeReponse } from '../models/api/responses/essential-data.response';
import { MessageResponse } from '../models/api/responses/message.response';
import { ApiService } from './api.service';
import { EssentialDataResponse } from '../models/api/responses/essentialDataOeList.response';

@Injectable({
  providedIn: 'root'
})
export class EssentialDataService {

  constructor(private readonly api: ApiService) { }

  /**
   * Call the web service "dce_donnees_essentielles_list" for get a essential data's list of one consultation
   * Method: POST
   * @param request request of WS
   * @returns Observable<DceEssentialDataList>
   */
  dceEssentialDataList(request: RequestForPaWithDCE): Observable<DceEssentialDataList> {
    return this.api.post(DceEssentialDataList, request, 'dce_donnees_essentielles_list');
  }

  /**
   * Call the web service "dce_donnees_essentielles_list" for add or edit a essential data
   * Method: POST
   * @param request request of WS
   * @returns Observable<DceEssentialData>
   */
  editEssentialData(request: EditEssentialDataRequest): Observable<DceEssentialData> {
    return this.api.post(DceEssentialData, request, 'edit_donnees_essentielles');
  }

  /**
   * Call the web service "oe_donnees_essentielles_edit" for add a economic operator to a essential data
   * Method: POST
   * @param request request of WS
   * @returns Observable<OeDeEdit>
   */
  oeDonneesEssentiellesEdit(request: OeDeEditRequest): Observable<OeDeEditReponse> {
    return this.api.post(OeDeEditReponse, request, 'oe_donnees_essentielles_edit');
  }

  /**
   * Call the web service "remove_oe_donnees_essentielles" for remove one economic operator from essential data
   * Method: DELETE
   * @param request request of WS
   * @returns Observable<MessageReponse>
   */
  removeOeDonneesEssenielles(request: RemoveOeDeRequest): Observable<MessageResponse> {
    return this.api.delete(MessageResponse, request, 'remove_oe_donnees_essentielles');
  }

  /**
   * Call the web service "donnee_essentielle_publish" for publish a essential data in AIFE
   * Method : POST
   * @param request request of WS
   * @returns Observable<PublishDeReponse>
   */
  publish(request: PublishDeRequest): Observable<PublishDeReponse> {
    return this.api.post(PublishDeReponse, request, 'donnee_essentielle_publish');
  }

  publishExecution(request: PublishDeRequest): Observable<PublishDeReponse> {
    return this.api.post(PublishDeReponse, request, 'donnee_essentielle_DExecution');
  }

  /**
   * Call the web service "avenant_edit" for add or edit a avenant of DE
   * Method : POST
   * @param request request of WS
   * @returns Observable<AvenantEditReponse>
   */
  avenantEdit(request: AvenantEditRequest): Observable<AvenantEditReponse> {
    return this.api.post(AvenantEditReponse, request, 'avenant_edit');
  }

  /**
   * Call th web service "delete_avenant" for delete a avenant of DE
   * Method : POST
   * @param request request of WS
   * @returns Observable<MessageResponse>
   */
  avenantDelete(request: AvenantDeleteRequest): Observable<MessageResponse> {
    return this.api.post(MessageResponse, request, 'delete_avenant');
  }

  /**
   * Call th web service "donnees_essentielles_oe_list" for get all DE list
   * Method : POST
   * @param request request of WS
   * @returns Observable<MessageResponse>
   */
    getDeOeList(request: EssentialDataOeListRequest): Observable<EssentialDataResponse> {
    return this.api.post(EssentialDataResponse, request, 'donnees_essentielles_oe_list');
  }
}

