<app-dialog [dialogRef]="dialogRef">

  <ng-container header>
    {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.HEADER' | translate | uppercase}}
  </ng-container>

  <!-- 
  <div header class="header full-width" fxLayout="row" fxLayoutAlign="start start">
    <div class="title text-bl" mat-dialog-title>
      
    </div>
  </div> -->
  <div body class="mat-content full-width" fxLayout="column">
    <div class="confirmation-recap">
      <div class="cons-id conf-elt text-bl">
        {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.CONS_ID' | translate:{consultationId: data.consId} }}
      </div>

      <div class="cons-id conf-elt">
        <span class="cons-id conf-elt">{{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OBJECT' |
          translate}}</span>
        <span class="blue-bold">{{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OBJECT_RES' | translate:{object:
          data.object} }}</span>
      </div>

      <div class="cons-id conf-elt">
        <span class="cons-id conf-elt">{{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.PROCEDURE' |
          translate}}</span>
        <span class="blue-bold">{{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.PROCEDURE_RES' |
          translate:{procedure: data.prodedure} }}</span>
      </div>

      <div class="cons-id conf-elt">
        <span class="cons-id conf-elt">{{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.PUBLICATION_DATE' |
          translate}}</span>
        <span class="blue-bold">{{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.PUBLICATION_DATE_RES' | translate:
          { date: datePublicationWithoutHours} }}</span>
      </div>

      <div class="cons-id conf-elt">
        <span class="cons-id conf-elt">{{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.CLOSURE_DATE' |
          translate}}</span>
        <span [ngClass]="{'red-bold': data.daysBeforeDeadline < 24, 'blue-bold': data.daysBeforeDeadline >= 24}">
          {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.CLOSURE_DATE_RES' | translate: { date: data.closureDate
          } }}
        </span>
      </div>
      <div class="cons-id conf-elt">
        <span> {{'APP.MARCHES_SECURISES.PA.CREATE.TABS.INFOS.DAYS_BEFORE_DEADLINE_PUB' | translate}}</span>
        <span class="text-bl">{{'APP.MARCHES_SECURISES.PA.CREATE.TABS.INFOS.DAYS_BEFORE_DEADLINE_PUB_RES' | translate:
          {days:data.daysBeforeDeadline, plurial: data.daysBeforeDeadline > 1 ? 's':''} }}</span>
      </div>


      <app-info-box *ngIf="data.infoProc && data.infoProc['1-2-24']">
        <span style="text-align: start" #content [innerHTML]="data.infoProc['1-2-24']"></span>
      </app-info-box>
    </div>

    <div class="inform-oe container" *ngIf="data.alreadyPublished">
      <form [formGroup]="form">
        <div class="checkbox-oe">
          <app-round-checkbox [disabled]="!data.nbDceRetrieved" name="informOeCb"
            (changeState)="informOe.setValue($event)">
            <label for="informOeCb"> {{ 'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.INFORM_OE' | translate: {
              nbOe: data.nbDceRetrieved } }}
            </label>
          </app-round-checkbox>
        </div>

        <mat-divider class="mat-divider-h" horizontal *ngIf="informOe.value"></mat-divider>

        <ng-container *ngIf="data.nbDceRetrieved; then thenTemplate; else elseTemplate"></ng-container>
        <ng-template #thenTemplate>
          <div class="form-oe" *ngIf="informOe.value">
            <div class="title" fxLayout="column">
              <div>
                {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.TITLE' | translate}}
              </div>
              <div>
                {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.NB_OE' | translate:{nbOe:nbRetrait} }}
              </div>
            </div>

            <div class="form">

              <div class="inputs-oe-form" fxLayout="row">
                <div class="label" fxFlex="10">
                  {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.SUBJECT' | translate}}
                </div>

                <div class="input" fxFlex="70">
                  <mat-form-field appearance="outline" class="full-width">
                    <input matInput formControlName="subject">
                  </mat-form-field>
                </div>

              </div>

              <div class="inputs-oe-form" fxLayout="row">
                <div class="label" fxFlex="10">
                  {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.SENDER' | translate}}
                </div>

                <div class="input" fxFlex="70">
                  {{data.reciever.server}}
                </div>

              </div>

              <div class="inputs-oe-form" fxLayout="row">
                <div class="label" fxFlex="10">
                  {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.MESSAGE' | translate}}
                </div>

                <div class="input" fxFlex="70">
                  <mat-form-field appearance="outline" class="mat-ang-textarea full-width textarea">
                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="10"
                      cdkAutosizeMaxRows="40" formControlName="emailContent"></textarea>
                  </mat-form-field>
                </div>

              </div>

              <div class="inputs-oe-form" fxLayout="row">
                <div class="label" fxFlex="10"></div>

                <div class="input" fxFlex="70">
                  <mat-checkbox color="primary" formControlName="shareOnQR">
                    {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.ADD_TO_QR' | translate}}
                  </mat-checkbox>
                </div>

              </div>

            </div>
          </div>

          <div class="info-oe-sent">
            {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.INFO1' | translate}}
            {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.INFO2' | translate}}
          </div>
        </ng-template>
        <ng-template #elseTemplate>
          {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.NO_RETRAITS' | translate}}
        </ng-template>


      </form>
    </div>
  </div>
  <div footer class="footer" fxLayout="row" fxLayoutAlign="end center" fxFlex="100">
    <button mat-stroked-button color="primary" (click)="confirm()">
      {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.BUTTON.CONFIRMATION' | translate}}
    </button>
    <button mat-stroked-button color="warn" (click)="close()">
      {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.BUTTON.CANCEL' | translate}}
    </button>
    <button mat-stroked-button cdkFocusInitial (click)="goToList()">
      {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.BUTTON.BACK' | translate}}
    </button>
  </div>
</app-dialog>