import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Icons } from '@atline-shared/enums/icons.enum';
import * as _ from 'lodash';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiErrorResponse } from 'src/app/core/models/api/responses/api.response';
import { EntDemCompItem } from 'src/app/core/models/entDemCompItem.model';
import { NotificationItem } from 'src/app/core/models/notificationItem.model';
import { EmailPaItem } from 'src/app/core/models/PaEmailItem.model';
import { DemandeCompService } from 'src/app/core/services/demande-comp.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { ComplementaryAction } from 'src/app/shared/enums/complementary.enum';

@Component({
  selector: 'app-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.scss']
})
export class EditEmailComponent implements OnInit {

  public form!: FormGroup;
  public email = new FormControl('', [Validators.email]);
  public error?: ApiErrorResponse;
  public emailAddingLoading = false;
  public emailRemoveLoading = false;
  icons = Icons;
  emails: EmailPaItem[] = [];
  // private emailAdded = false;


  constructor(
    private readonly fromBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditEmailComponent>,
    private readonly demandeCompService: DemandeCompService,
    private readonly notificationsService: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: { emails: EmailPaItem[], state: ComplementaryAction, cleDce: string, cle: string, cleEtab: string }) { }

  ngOnInit(): void {
    this.form = this.fromBuilder.group({
      email: this.email
    });
    this.emails = new Array<EmailPaItem>(...this.data.emails)

  }

  /**
   * add email to a DC
   */
  public addEmail(): void {
    if (this.email.valid && !_.isEmpty(this.email.value)) {
      this.emailAddingLoading = true;
      if (this.data.state === ComplementaryAction.DEMANDE)
        this.demandeCompService.addEmailDC({
          cle_etab: this.data.cleEtab,
          cle_dce: this.data.cleDce,
          cle_demande: this.data.cle,
          adresse_email: this.email.value
        }).pipe(
          catchError(err => {
            this.emailAddingLoading = false;
            const apiError = err.error as ApiErrorResponse;
            if (apiError) {
              this.error = apiError;
              alert(this.error?.error_description);
            }
            return throwError(err);
          })
        ).subscribe(res => this.callbackApi(res));

      if (this.data.state === ComplementaryAction.NOTIFICATION)
        this.notificationsService.addEmail({
          cle_etab: this.data.cleEtab,
          cle_dce: this.data.cleDce,
          cle_notification: this.data.cle,
          adresse_email: this.email.value
        }).pipe(
          catchError(err => {
            this.emailAddingLoading = false;
            const apiError = err.error as ApiErrorResponse;
            if (apiError) {
              this.error = apiError;
              alert(this.error?.error_description);
            }
            return throwError(err);
          })
        ).subscribe(res => this.callbackApi(res));
    }
  }


  /**
   * remove email of DC
   */
  public removeEmail(email: string): void {
    this.emailRemoveLoading = true;
    if (this.data.state === ComplementaryAction.DEMANDE)
      this.demandeCompService.deleteEmailDC({
        cle_etab: this.data.cleEtab,
        cle_dce: this.data.cleDce,
        cle_demande: this.data.cle,
        adresse_email: email
      }).pipe(
        catchError(err => {
          this.emailRemoveLoading = false;
          const apiError = err.error as ApiErrorResponse;
          if (apiError) {
            this.error = apiError;
          }
          return throwError(err);
        })
      ).subscribe(res => this.callbackApi(res));

    if (this.data.state === ComplementaryAction.NOTIFICATION)
      this.notificationsService.removeEmail({
        cle_etab: this.data.cleEtab,
        cle_dce: this.data.cleDce,
        cle_notification: this.data.cle,
        adresse_email: email
      }).pipe(
        catchError(err => {
          this.emailRemoveLoading = false;
          const apiError = err.error as ApiErrorResponse;
          if (apiError) {
            this.error = apiError;
          }
          return throwError(err);
        })
      ).subscribe(res => {
        this.callbackApi(res)
      });
  }


  private callbackApi(res: NotificationItem | EntDemCompItem): void {
    this.emailAddingLoading = false;
    this.emailRemoveLoading = false;
    this.emails = res.email_destinataire as EmailPaItem[];
    // this.data.emails = (typeof res.email_destinataire === 'string') ? res.email_destinataire : res.email_destinataire as EmailPaItem[];
    this.email.reset();
  }


}
