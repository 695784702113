import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Icons } from '@atline-shared/enums/icons.enum';
import { Debounce } from '@atline/core/decorators/debounce.decorator';
import { ParamsDictionnaryLot } from '@atline/core/models/api/requests/dictionnaryLot.request';
import { DictionnaryLotResponse } from '@atline/core/models/api/responses/dictionnaryLot.reponse';
import { TypeLotDictionnary } from '@atline/core/models/dictionnaryLotItem.model';
import { Pagination } from '@atline/core/models/pagination.model';
import { LotService } from '@atline/core/services/lot.service';
import { UtilsService } from '@atline/core/services/utils.service';
import { PartialObserver } from 'rxjs';

@Component({
  selector: 'app-dictionnary-lot-dialog',
  templateUrl: './dictionnary-lot-dialog.component.html',
  styleUrls: ['./dictionnary-lot-dialog.component.scss']
})
export class DictionnaryLotDialogComponent implements OnInit {

  readonly iconLoupe = Icons.Loup;

  readonly resultat = new DictionnaryLotResponse({ pagination: new Pagination({ sens_ordre: 'asc' }) });
  public recherche = '';

  public init = false;
  public loaded = false;

  constructor(
    private readonly lotService: LotService,
    private readonly utils: UtilsService,
    readonly dialogRef: MatDialogRef<DictionnaryLotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: { type?: TypeLotDictionnary, clePa: string },
  ) { }

  ngOnInit(): void {
    this.callWS({
      next: r => {
        Object.assign(this.resultat, r);
        this.init = true;
        this.loaded = true;
      },
      error: () => {
        this.init = true;
        this.loaded = true;
      }
    });
  }

  get page(): number {
    return this.resultat.pagination.page;
  }

  get nbParPage(): number {
    return this.resultat.pagination.nbr_par_page;
  }

  get size(): number {
    return this.resultat.nb_total;
  }

  search(value: string): void {
    if (this.recherche !== value) {
      this.recherche = value;
      this.resultat.pagination.page = 1;
      this.reload();
    }
  }

  @Debounce(100)
  reload(): void {
    this.loaded = false;
    this.callWS({
      next: r => {
        Object.assign(this.resultat, r);
        this.loaded = true;
      },
      error: () => {
        this.loaded = true;
      }
    });
  }


  paginatorChange(e: PageEvent): void {
    this.resultat.pagination.page = this.resultat.pagination.nbr_par_page === e.pageSize ? e.pageIndex + 1 : 1;
    this.resultat.pagination.nbr_par_page = e.pageSize;
    this.reload();
  }

  private callWS(callback?: PartialObserver<DictionnaryLotResponse>): void {
    const cleEtab = this.utils.cleEtab;
    const params = new ParamsDictionnaryLot('lot', this.resultat.pagination, this.recherche);
    if (cleEtab && this.data.clePa) this.lotService.getDictionnary(cleEtab, this.data.clePa, params).subscribe(callback);
    else callback?.error?.(undefined);
  }
}
