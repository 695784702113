import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminUserService } from '@atline/core/services/adminUser.service';
import { DialogsService } from '@atline/core/services/dialogs.service';
import { UtilsService } from '@atline/core/services/utils.service';
import { EMAIL_REGEX, NUMBER_REGEX } from '@atline/core/models/constant-variable/regex.model';
import { AdminUserCreateRequest } from '@atline/core/models/api/requests/adminUserCreate.request';
import { ConstEnum } from '@atline-shared/enums/const.enum';
import * as _ from 'lodash';
import { Icons } from '@atline-shared/enums/icons.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminUserItem } from '@atline/core/models/adminUser.model';
import { MaskRegExpEnum } from '@atline-shared/enums/maskExp.enum';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  public country = new FormControl(ConstEnum.FRANCE_LABEL);
  readonly genders: { value: string, display: string }[] = [
    { value: "mr", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.CIVILITY_DISPLAY.MR' },
    { value: "mme", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.CIVILITY_DISPLAY.MME' },
    { value: "mlle", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.CIVILITY_DISPLAY.MLLE' }
  ];

  readonly fonctions: { value: string, display: string }[] = [
    { value: "", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.FONCTION_DISPLAY.DEFAULT' },
    { value: "adm", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.FONCTION_DISPLAY.ADMINISTRATION' },
    { value: "com", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.FONCTION_DISPLAY.COMMERCIAL' },
    { value: "dir", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.FONCTION_DISPLAY.DIRIGEANT' },
    { value: "tec", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.FONCTION_DISPLAY.TECHNICIEN' },
    { value: "sm", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.FONCTION_DISPLAY.SERVICE_MARCHE' }
  ];
  readonly timeZone: String[] = ["Europe/Paris", "Africa/Casablanca"];

  readonly onglets: { value: string, display: string }[] = [
    { value: "", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.DEFAULT' },
    { value: 'seal|liste', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.JOUE_BOAMP_JAL' },
    { value: 'fullweb|liste', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.CONSULTATIONS_NOUVELLES' },
    { value: 'consultation|en_cours', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.CONSULTATIONS_EN_COURS' },
    { value: 'consultation|archivees', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.CONSULTATIONS_ARCHIVEES' },
    { value: 'ressources|ressources', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.RESSOURCES' },
    { value: 'mon_compte|mon_compte', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.DOC_BON_COMMANDE' },
    { value: 'faq', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.FAQ' },
    { value: 'admin|administration', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.ADMINISTRATION' },
    { value: 'entreprise', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.ENTREPRISE' },
  ];

  public addUser: FormGroup;
  public hide = true;
  public vone = false;
  public vtwo = false;
  public java = false;

  icons = {
    plus: Icons.Plus,
    eye: Icons.eye,
    group: Icons.group,
    check: Icons.Check
  };

  masks = MaskRegExpEnum;

  constructor(
    public dialogRef: MatDialogRef<AddUserComponent>,
    private fb: FormBuilder,
    private readonly dialogService: DialogsService,
    private adminUserService: AdminUserService,
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: { user: AdminUserItem | null }
  ) {
    this.addUser = this.fb.group({
      nom: fb.control(data.user?.lastName, [Validators.required]),
      prenom: fb.control(data.user?.firstName, [Validators.required]),
      login: fb.control(data.user?.login, [Validators.required, Validators.pattern('^[a-zA-Z0-9]{4,}$')]),
      email: fb.control('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
      genre: [data.user?.genre ?? this.genders[0].value, [Validators.required]],
      adresse1: fb.control(data.user?.adresse1, [Validators.nullValidator]),
      adresse2: fb.control(data.user?.adresse2, [Validators.nullValidator]),
      codePostal: fb.control(data.user?.codePostal, [Validators.nullValidator]),
      commune: fb.control('', [Validators.nullValidator]),
      country: this.country,
      tel: fb.control(data.user?.tel, [Validators.pattern(NUMBER_REGEX)]),
      fax: fb.control(data.user?.fax, [Validators.pattern(NUMBER_REGEX)]),
      timezone: fb.control(data.user?.timezone ?? this.timeZone[0], [Validators.nullValidator]),
      password: fb.control(null, [Validators.required, Validators.pattern(/^(?!.*\s).{6,}$/)]),
      fonction: fb.control(null, [Validators.nullValidator]),
      onglet: fb.control(null, [Validators.required])
    });

    this.country.setValue(
      ConstEnum.FRANCE_ISO_CODE
    );
  }

  ngOnInit(): void {

  }

  getCountry(event: any): void {
    if (!_.isEmpty(event) && !_.isNil(event))
      this.country.setValue(event);
  }

  onConfirm(): void {
    if (this.addUser.value && this.addUser.valid) {
      const cle_etab = this.utils.cleEtab || '';
      const cle_ent = this.utils.cleEnt || '';
      const {
        nom: lastName,
        prenom: firstName,
        login,
        password,
        email,
        genre,
        adresse1,
        adresse2,
        codePostal,
        commune,
        tel,
        fax,
        timezone
      } = this.addUser.value;

      let v1:1|0 = 0;
      let v2:1|0 = 0;
      let java:1|0 = 0;

      if (this.vone == true) {
        v1 = 1;
      }

      if (this.vtwo == true) {
        v2 = 1;
      }

      if (this.java == true) {
        java = 1;
      }


      const adminUserCreateRequest: AdminUserCreateRequest = {
        login,
        password,
        civility: genre,
        name: lastName,
        firstName,
        address: JSON.stringify({
          adresse_1: adresse1,
          adresse_2: adresse2,
          code_postal: parseInt(codePostal),
          commune: commune,
          pays: this.addUser?.get('country')?.value
        }),
        email,
        tel,
        fax,
        timezone,
        cle_etab,
        cle_ent,
        qualification: this.addUser?.get('fonction')?.value,
        autorized_apps: JSON.stringify({"demo_fw":0, "bo":0 , "ms2":1 , "asec":0 , "bb":0 , "ts":0 , "es":0, "helios":0, "acte":0, "parapheur":0, "convocation":0, "espace":0, "attestations":0}),
        paramsMS: JSON.stringify({
          defaultTab: this.addUser?.get('onglet')?.value,
          fw1: v1,
          fw2: v2,
          java: java
        })
      };

      this.adminUserService
        .addUser(adminUserCreateRequest)
        .subscribe(
      () => {
        // this.UsersList.show =false;
        // this.UsersList.added =true;
        // this.UsersList.callToWs();
      },
      () => {
        // this.UsersList.show =true;
        // this.UsersList.added =false;
      }
  );

      this.dialogService.closeCreationGroupDialog(true);
      return;
    }
  }

  onDismiss(): void {
    this.dialogService.closeCreationGroupDialog(false);
  }

  close(): void {
    this.dialogRef.close();
  }
}
