<div class="container">
  <button mat-stroked-button [id]="idValue" class="button" [ngClass]="customClass" (mouseenter)="isOnHover = true"
    (mouseleave)="isOnHover = false" [ngStyle]="customStyles" [disabled]="disabledValue" [ngStyle]="customStyles"
    (click)="click()">
    <div fxLayout="row" fxLayoutAlign="center center" [fxLayoutGap]="hasInternalContent ? '5px' : '0px'">
      <mat-icon *ngIf="icon" [svgIcon]="icon" class="full-width" fxLayout="row" fxLayoutAlign="center center"></mat-icon>
      <!-- <div *ngIf="(btnContent.children.length || btnContent.innerHTML !== '') && icon " class="icon" [ngStyle]="customStylesContent"></div> -->
      <div #customContent class="content">
        <ng-content></ng-content>
      </div>
    </div>
  </button>
</div>
