import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SsoComponent } from './sso/sso.component';
import { environment } from 'src/environments/environment';
import { MarchesSecurisesComponent } from './marches-securises/marches-securises.component';

const routes: Routes = [
  {
    path: 'home', component: HomeComponent,
    loadChildren: async () => (await import('./home/home.module')).HomeModule
  },
  {
    path: 'redirect-sso', component: SsoComponent,
    loadChildren: async () => (await import('./sso/sso.module')).SsoModule
  }
];



switch (window.location.hostname) {
  case environment.domain.ats:
  case environment.domain.atswww:
  case environment.domain.local:
  case environment.domain.atsPreprod:
  case environment.domain.dev:
    routes.push({
      path: 'marches-securises', component: MarchesSecurisesComponent,
      loadChildren: async () => (await import('./marches-securises/marches-securises.module')).MarchesSecurisesModule
    });

    routes.push({ path: '', redirectTo: 'marches-securises', pathMatch: 'full' })
    routes.push({ path: '**', redirectTo: 'marches-securises', pathMatch: 'full' })
    break;

  case environment.domain.ms:
    routes.push({
      path: '', component: MarchesSecurisesComponent,
      loadChildren: async () => (await import('./marches-securises/marches-securises.module')).MarchesSecurisesModule
    })
    routes.push({
      path: '**', component: MarchesSecurisesComponent,
      loadChildren: async () => (await import('./marches-securises/marches-securises.module')).MarchesSecurisesModule
    })
    break;

  default:
    break;
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading, anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
