import { BooleanValue } from '@atline/core/types/boleanMs.type';
import { ApiRequest } from './api.request';
import { EtapeConsultation } from '@atline-shared/enums/EtapeConsultation.emun';

// export type EtapeConsultation = 'toutes' | 'en_cours' | 'phase_passation' | 'attribuee' | 'deleted';

// tslint:disable:variable-name
export class ConsultationRequest extends ApiRequest {
  cle_etab?: string;
  cle_dce?: string;
  motscles?: string;
  type_prestation?: string;
  type_marche?: 'public' | 'ordonnance2005' | 'prive';
  cle_pa?: string;
  departement?: string;
  etape_consultation?: EtapeConsultation;
  demo?: boolean;
  restreinte?: boolean;
  supprimer?: 0 | 1;
  is_dume?: 0 | 1;
  criteres_environnementaux?: boolean;
  criteres_sociaux?: boolean;
  nbr_par_page ?= 10;
  page ?= 1;
  ordre?: 'date_cloture' | 'date_publication';
  sens_ordre?: 'desc' | 'asc' = 'asc';
  as_pa ?= 0;
  cle_affaire?: string;
  sem?: 1 | 0 | null;
}

export class ConsultationPiecesListResquest extends ApiRequest {
  constructor(
    public cle_etab?: string,
    public cle_dce?: string,
    public code_restreinte?: string,
    public ip_orgin?: string,
    public cle_pa?: string,
  ) { super(); }
}

export class ConsultationGetPieceRequest extends ApiRequest {
  cle_etab?: string;
  cle_dce?: string;
  code_restreinte?: string;
  cle_piece?: string;
  content_type?: string;
}

export class GetConsultationDetailsRequest extends ApiRequest {

  /**
   * cle de l'établissement
   */
  cle_etab?: string;

  /**
   * cle de de la consultation
   */
  cle_dce?: string;

  /**
   * code dans le cas de la consultation restreinte
   */
  code_restreinte?: string;
}


export class CreateConsultationRequest extends ApiRequest {
  cle_etab!: string;
  cle_pa!: string;
  cle_pa_lib?: string;
  cle_affaire?: string;
  cle_affaire_lib?: string;
  cle_dce?: string;
  cle_groupe?: string | null;
  objet_dce?: string;
  finalite_marche?: string;
  type_marche?: string;
  type_prestation?: string;
  type_pa?: string;
  passation?: string;
  passation_label?: string;
  ref_interne?: string | null;
  date_publication?: string;
  date_cloture?: string;
  departements_prestation?: string;
  emails?: string;
  emails_oe?: string;
  emails_oe_comp?: string;
  mail_auto?: BooleanValue;
  informatique?: BooleanValue;
  allotie?: BooleanValue;
  en_ligne?: BooleanValue | string;
  invisible?: BooleanValue;
  option_reponse?: string;
  format_reponse?: string;
  signature_obligatoire?: BooleanValue;
  format_signature?: string;
  structure_interne?: string;
  criteres_environnementaux?: boolean;
  criteres_sociaux?: boolean;
  publication?: BooleanValue;
  is_dume?:  BooleanValue;
  prix_dce?: string;
  libre_ms?: string | number | BooleanValue;
  infos_tarifs?: string | null;
  cle_annonce?: string | null;
  cle_annonceur?: string | null;
  type_formulaire?: string;
}

export class PostArchiveDceRequest extends ApiRequest {
  cle_etab!: string;
  cle_pa!: string;
  cle_dce!: string;
  is_archive!: boolean;
}
export class SendToJalRequest extends ApiRequest {
  cle_etab!: string;
  cle_pa!: string;
  cle_dce!: string;
  cle_piece!: string;
  emails_jal!: string;
}

export class DeriverConsultationRequest extends ApiRequest {
  cle_etab!: string;
  cle_pa!: string;
  cle_parent_dce!: string;
  avec_lots!: 0 | 1;
  avec_pieces!: 0 | 1;
  avec_groupe!: 0 | 1;
}

export class ConsulterTitulaireRequest extends ApiRequest {
  cle_etab!: string;
  cle_pa!: string;
  cle_parent_dce!: string;
  cle_parent_lot?: string;
  cles_attributaires?: string;
  cles_groupes?: string;
}

export class GetConsultationJournal extends ApiRequest {
  cle_etab!: string;
  cle_pa!: string;
  cle_dce!: string;
  type_format!: 1 | 2;
}

export class GetConsultationJournnal extends ApiRequest {
  cle_etab!: string;
  cle_pa!: string;
  cle_dce!: string;
  type_pdf!: string;
  type_format!: 1 | 2;
}

export class CreateSemRequest {
  cle_etab!: string;
  cle_pa!: string;
  cle_dce!: string;
  cle_attributaires!: string;
  cle_lot?: string;
  cles_groupes?: string;
}


// tslint:enable:variable-name
