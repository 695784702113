// import fns_simple_initial from './displays/fns-simple-initial.model';
// import eform_1 from './displays/e-form-1';
import eform_16 from './displays/e-form-16';
// import eform_2 from './displays/e-form-2';
// import eform_3 from './displays/e-form-3';
// import eform_18 from './displays/e-form-18';
// import eform_modification_attribution from './displays/e-form-rectif-attribution';
// import eform_modification from './displays/e-form-rectif-initial';
// import eform_4 from './displays/e-form-4';
// import eform_29 from './displays/e-form-29';
// import eform_31 from './displays/e-form-31';
// import eform_30 from './displays/e-form-30';
// import eform_17 from './displays/e-form-17';

const forms = {
  // eform_1,
  // eform_2,
  // eform_3,
  // fns_simple_initial,
  // eform_29,
  eform_16,
  // eform_17,
  // eform_30,
  // eform_modification_attribution,
  // eform_modification,
  // eform_18,
  // eform_31,
  // eform_4
} as const;

export default forms;
