import { AffaireItem } from '../../affaireItem.model';
import { Pagination } from '../../pagination.model';

export class AffaireListResponse {
  affaires: AffaireItem[] = [];
  nb_total = 0;
  pagination!: Pagination;

}

// tslint:enable:variable-name
