import { DefaultResponse } from './../models/api/responses/default.response';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DepotsRetraitListRequest } from '../models/api/requests/depotsRetraitList.request';
import { RemoveRetraitPapierRequest, RetraitPapierRequest } from '../models/api/requests/retraitPapier.request';
import { RetraitsListReponse } from '../models/api/responses/depotsRetraitList.reponse';
import { ApiService } from './api.service';
import { RetraitPapierResponse } from '../models/api/responses/retraitPapier.response';

@Injectable({
  providedIn: 'root'
})
export class RetraitService extends ApiService {


  retraitsList(retraitsListRequest: DepotsRetraitListRequest): Observable<RetraitsListReponse> {
    return this.post(RetraitsListReponse, retraitsListRequest, 'retraits_list');
  }

  retraitsPapierList(retraitsListRequest: DepotsRetraitListRequest): Observable<RetraitsListReponse> {
    return this.post(RetraitsListReponse, retraitsListRequest, 'retraits_papier_list');
  }

  postRetraitPapier(req: RetraitPapierRequest): Observable<RetraitPapierResponse> {
    return this.post(RetraitPapierResponse, req, 'retrait_papier_edit');
  }

  deleteRetraitPapier(req: RemoveRetraitPapierRequest): Observable<any> {
    return this.post(DefaultResponse, req, 'retrait_papier_remove');
  }

  getDetailedRegistre(req: DepotsRetraitListRequest) {
    return this.post(RetraitsListReponse, req, 'retraits_list_detailed')
  }
  retraitsPapierListDetailed(retraitsListRequest: DepotsRetraitListRequest): Observable<RetraitsListReponse> {
    return this.post(RetraitsListReponse, retraitsListRequest, 'retraits_list_papier_detailed');
  }
}
