import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { AvisGetRequest } from '../models/api/requests/avis-get.request';
import { AvisGetResponse } from '../models/api/responses/avis-get.response';
@Injectable({
  providedIn: 'root'
})
export class AvisGetService {
  constructor(private readonly apiService: ApiService) {

  }

  getAvisAttribution(request: AvisGetRequest): Observable<AvisGetResponse> {
    return this.apiService.post<AvisGetResponse>(AvisGetResponse, request, 'avis_get');

  }

  getPiece(cle_avis: string): Observable<HttpResponse<Blob>> {
    const endpoint = 'avis_get';
    const body = { cle_avis }; 
    return this.apiService.postDownloadBlob(endpoint, body);
  }

}
