import { TokenProviderEnum } from '@atline-shared/enums/tokenProvider.enum';
import { GroupItem } from '../../groupItem.model';
// tslint:disable:variable-name
type Role = 'PA' | 'OE';

export class UserReponse {
  cle_etab = '';
  entreprise = '';
  demo = 0;
  email = '';
  last_name = '';
  first_name = '';
  role: Role = 'PA';
  nom_etablissement = '';
  nom_entreprise = '';
  affaire = 0;
  groupe = 0;
}

export class TokenGroup {
  ms: GroupItem[] = [new GroupItem()];
  atst: GroupItem[] = [new GroupItem()];
}

export class PaToken {
  denomination_pa = '';
  logo_ent = '';
  cle_pa = '';
  cle_annonceur = '';
  aor_invisible!: boolean;
  archivage!: boolean;
  chorus!: boolean;
  eoplus!: boolean;
  dume!: boolean;
  eoppp!: boolean;
  mps!: boolean;
  ouverture_anticipee!: boolean;
  publication_auto!: boolean;
  sem!: boolean;
  type_pa!: string;
}
export class ProfileReponse {
  user = new UserReponse();
  pa_list: PaToken[] = [];
  rights = {tender: ''};
  groups = new TokenGroup();
  ms_rights = new MsRightItem();
}

export class TokenResponse {
  access_token = '';
  refresh_token = '';
  user_id = 0;
  expires_in = 0;
  expires!: number;
  user_name = '';
  timezone = '';
  provider!: TokenProviderEnum
  profile = new ProfileReponse();
  dateCreation = new Date();
  validation_1st_level = false;
  validation_2nd_level = false;
}

export class AuthorizeResponse {
  Location = '';
}

export class MsRightItem {
  D_ADMINISTRATEUR = false;
  D_ACCES_AS = false;
  D_ACCES_ATST = false;
  D_SUPERVISION_DCE = false;
  D_ACCES_RR = false;
  D_ACCES_RD = false;
  D_MAIL_EN = false;
  D_MAIL_ENT = false;
  D_MAIL_OE = false;
  D_GESTION_MODELE = false;
  D_SUP_DCE = false;
  D_VALIDATION_DCE = false;
  D_VALIDATION_DCE_2 = false;
  D_MODIF_NIVEAU_1 = false;
  D_MODIF_NIVEAU_2 = false;
  D_ACCES_CAO = false;
  D_ENVOI_DEMANDE_COMP = false;
  D_OUVRIR_DEMANDE_COMP = false;
  D_ENVOI_NOTIFICATION = false;
  D_COFFRE_ANNUAIRE = false;
  D_EXECUTION = false;
  D_ENVOI_LRAR = false;
  D_ANNONCES = false;
  D_REDAC_DCE = false;
  E_PROJETS = false;
  D_WEB_SERV = false;
  D_BOAMP_CONFIG = false;
}
// tslint:enable:variable-name
