<app-dialog [dialogRef]="dialogRef">

  <div header>
    {{'APP.MARCHES_SECURISES.PA.AWARD_NOTIFICATION.MODAL.TITLE' | translate | uppercase}}
  </div>

  <div body>
    <form class="content" [formGroup]="form">

      <div class="type">
        <mat-radio-group aria-label="Select an option" formControlName="type" fxLayout="row"
          fxLayoutAlign="space-between">
          <mat-radio-button color="primary" [value]="0">{{'APP.MARCHES_SECURISES.PA.AWARD_NOTIFICATION.SELECT.SUCCESS' |
            translate}}</mat-radio-button>
          <mat-radio-button color="primary" [value]="1">
            {{'APP.MARCHES_SECURISES.PA.AWARD_NOTIFICATION.SELECT.WITHOUT_ISSUE' | translate}}</mat-radio-button>
          <mat-radio-button color="primary" [value]="2">{{'APP.MARCHES_SECURISES.PA.AWARD_NOTIFICATION.SELECT.NOBODY' |
            translate}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-divider class="mat-divider-h" horizontal></mat-divider>

      <div class="description" fxLayout="row" fxLayoutAlign="start start">
        <div class="label text-bold" fxFlex="25">
          {{'APP.MARCHES_SECURISES.PA.AWARD_NOTIFICATION.FORM.DESCRIPTION' | translate}}<span class="required"></span>
        </div>
        <div class="textarea full-width">
          <mat-form-field class="full-width form-field" appearance="outline">
            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="10" formControlName="description" class="text-area-not-rised"></textarea>
          </mat-form-field>
        </div>
      </div>

      <mat-divider class="mat-divider-h" horizontal></mat-divider>

      <div class="dates" fxLayout="column">
        <div class="date" fxLayout="row" fxLayoutAlign="start center">
          <div class="label text-bold" fxFlex="25">
            {{'APP.MARCHES_SECURISES.PA.AWARD_NOTIFICATION.FORM.START_DATE' | translate}}<span class="required"></span>
          </div>
          <div class="input" fxFlex="40" fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline">
              <input matInput [matDatepicker]="pickerStart"
                [placeholder]="'APP.MARCHES_SECURISES.PLACEHOLDER.JJ/MM/AAAA' | translate"
                formControlName="notificationDate" class="datepickerInput" [max]="limitDate.value">
              <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
              <mat-datepicker #pickerStart></mat-datepicker>
            </mat-form-field>
            <button mat-button class="bin icon-bg" (click)="notificationDate.reset()"></button>
          </div>
        </div>


        <mat-divider class="mat-divider-h" horizontal></mat-divider>


        <div class="date" fxLayout="row" fxLayoutAlign="start center">
          <div class="label text-bold" fxFlex="25">
            {{'APP.MARCHES_SECURISES.PA.AWARD_NOTIFICATION.FORM.END_DATE' | translate}}<span class="required"></span>
          </div>
          <div class="input" fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline">
              <input matInput [matDatepicker]="pickerLimit"
                [placeholder]="'APP.MARCHES_SECURISES.PLACEHOLDER.JJ/MM/AAAA' | translate" formControlName="limitDate"
                class="datepickerInput" [min]="notificationDate.value">
              <mat-datepicker-toggle matSuffix [for]="pickerLimit"></mat-datepicker-toggle>
              <mat-datepicker #pickerLimit></mat-datepicker>
            </mat-form-field>
            <button mat-button class="bin icon-bg" (click)="limitDate.reset()"></button>
          </div>
        </div>

        <mat-divider class="mat-divider-h" horizontal></mat-divider>

        <div class="file" fxLayout="row" fxLayoutAlign="start center">
          <div class="label text-bold" fxFlex="25">
            {{'APP.MARCHES_SECURISES.PA.AWARD_NOTIFICATION.FORM.FILE' | translate}}<span class="required"></span>
          </div>
          <div class="input-file">
            <label for="fileDc" fxLayout="row" fxLayoutAlign="start center">
              <div class="choose-file">
                {{'APP.MARCHES_SECURISES.DC.CHOOSE_FILE' | translate}}
              </div>
              <div class="no-file" *ngIf="fileName === ''; else fileNameTemplate">
                {{'APP.MARCHES_SECURISES.DC.NO_FILE_SELECTED' | translate}}
              </div>
              <ng-template #fileNameTemplate>
                <div class="file-name">
                  {{fileName}}
                </div>
              </ng-template>



            </label>
            <input type="file" id="fileInput" (change)="uploadFile($event.target)">
          </div>
        </div>
      </div>

      <!-- <div class="validate" fxLayout="row" fxLayoutAlign="end center">
        
      </div> -->

    </form>
  </div>

  <div footer fxFlex="100" fxLayout="row" fxLayoutAlign="end center">
    <app-button size="text" [icon]="Icons.Check" [disabled]="!canSend" (click)="save()"></app-button>
  </div>

</app-dialog>




<!-- 


<div class="modal">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between start">
    <div class="title">

    </div>
    <div class="close-cross" (click)="close()">
      x
    </div>
  </div>

 


</div> -->