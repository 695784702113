import {
  Component,
  OnInit,
  OnChanges,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CountryService } from '@atline/core/services/country.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CountryItem } from '@atline/core/models/countryItem.model';
import { CountryResponse } from '@atline/core/models/api/responses/country.response';
import * as _ from 'lodash';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
})
export class CountryComponent implements OnInit, OnChanges {
  @Input() countryStr?: string;
  @Output() country = new EventEmitter<string>();
  @Input() iso?: 2 | 3;
  @Output() countryDataReady = new EventEmitter<void>();

  public selectedCountry: CountryItem | undefined;

  public countryArr!: CountryResponse;
  public form!: FormGroup;
  public countryFC = new FormControl();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly countryService: CountryService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.countryStr) {
      const newCountryObj = changes.countryStr.currentValue;

      if (newCountryObj && newCountryObj.iso) {
        const countryItem = this.getCountryItemByIso(newCountryObj.iso);

        if (countryItem) {
          this.setCountry(countryItem);
        }
      }
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      adr_pays: this.countryFC,
    });

    this.countryService
      .getCountry()
      .pipe(catchError((err) => throwError(err)))
      .subscribe((country) => {
        this.countryArr = country;

        if (this.countryStr) {
          const countryItem = this.getCountryItemByIso(this.countryStr);
          if (countryItem) {
            this.setCountry(countryItem);
          }
        }
      });

    this.form.valueChanges.subscribe((value) => {
      if (value.adr_pays) {
        this.country.emit(_.toUpper(value.adr_pays.iso));
      } else {
        this.country.emit('');
      }
    });
  }

  private getCountryItemByIso(iso: string): CountryItem | undefined {
    return _.find(
      this.countryArr?.countries,
      (country) => country.iso === _.upperCase(iso)
    );
  }

  // private getCountryItemByName(name: string): CountryItem | undefined {
  //   return _.find(this.countryArr?.countries, ['name', name]);
  // }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private setCountry(country: CountryItem) {
    // Set the form control value without emitting an event.
    this.countryFC.setValue(country, { emitEvent: false });
  }
}
